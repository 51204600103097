import { Box, Button, Divider, Typography } from "@mui/material"
import { useDeleteSupportPlan } from "api/support-plan";
import Loader from "components/Loader";
import MainCard from "components/MainCard"
import BasicModal from "components/modal/BasicModal";
import { PageUrl } from "const/page-url";
import usePreventDoubleClick from "hooks/usePreventDoubleClick";
import React, { useState } from "react"
import { useNavigate } from "react-router";
import { SupportPlanData } from "types/support-plan";
import { SnackbarProps } from 'types/snackbar';
import { openSnackbar } from 'hooks/useGetSnackbar';

function DeleteSupportPlan(data: SupportPlanData) {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { deleteSupportPlan, isDeletingSupportPlan } = useDeleteSupportPlan()

    const { handleClick } = usePreventDoubleClick(async () => {
        await deleteSupportPlan(data.childId)
        // ダイアログを閉じる
        setOpenModal(false);
        navigate(PageUrl.SupportPlanList.urlWithSlash, { replace: true })
        openSnackbar({
            open: true,
            message: "個別支援計画を削除しました",
            variant: 'alert',
            alert: {
                color: 'success'
            }
        } as SnackbarProps);
    })

    return <React.Fragment>
        {isDeletingSupportPlan && <Loader />}
        <Divider sx={{ color: "red", "&::before, &::after": { borderColor: "red" } }}>Danger Zone</Divider>
        <Box sx={{ mt: 2 }}>
            <Typography variant="h2" sx={{ pt: 2, pb: 2 }}>
                個別支援計画の削除
            </Typography>
            <MainCard borderRed={true}>
                <Typography variant="subtitle1">
                    {data.childFullName}さんの個別支援計画書を完全に削除します
                </Typography>
                <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, mb: 2 }}
                    onClick={async () => {
                        setOpenModal(true)
                    }}>
                    削除する
                </Button>
            </MainCard>
        </Box>
        <BasicModal
            open={openModal}
            setOpen={setOpenModal}
            title={"本当に削除しますか?"}
            description={`過去の個別支援計画も削除されます`}
            submitButtonString='実行する'
            onCLickPositiveButton={handleClick} />
    </React.Fragment>
}
export default DeleteSupportPlan;