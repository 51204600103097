export const formatToKanjiDateString = (value: string) => {
    const date = new Date(value)
    const year = date.getFullYear().toString()
    const month = (date.getMonth() + 1).toString()
    const day = date.getDate().toString()
    return year + '年' + month + '月' + day + '日'
}

export const convertToKanjiDateString = (date: Date) => {
    const year = date.getFullYear().toString()
    const month = (date.getMonth() + 1).toString()
    const day = date.getDate().toString()
    return year + '年' + month + '月' + day + '日'
}

export const convertToLocaleDateString = (date: undefined | Date) => {
    if (date == undefined) return undefined
    return (date as unknown as Date).toLocaleDateString()
}