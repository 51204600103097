import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// action - state management
import { LOGIN, LOGOUT } from 'contexts/auth-reducer/actions';
import authReducer from 'contexts/auth-reducer/auth';

// project import
import { AuthProps, AuthContextType, UserProfile } from 'types/auth';
import { CommonString } from 'const/common-string';
import { executeFirebaseApi } from 'api/error/ohana-error';
import Loader from 'components/Loader';
import { EmailAuthProvider } from '@firebase/auth';
import { axiosClient } from 'components/AxiosConfig';
import { Authority } from 'enum/authority';

const ApiEndPoint = {
    Login: "/api/account/login",
    UpdateEmail: "/api/account/update-email",
} as const;

// Firebaseの初期化
if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    });
}

// ユーザーの初期値
const initialState: AuthProps = {
    isLoggedIn: false,
    isDeveloper: false,
    isInitialized: false,
    user: null
};

// ユーザー情報をローカスストレージに保存する
const setParamsToLocalStorage = (param: {
    userAuthority?: string | null,
    userFamilyNameKanji?: string | null,
    userFirstNameKanji?: string | null,
    userFamilyNameHiragana?: string | null,
    userFirstNameHiragana?: string | null,
    userEmail?: string | null,
    userClassId?: string | null,
    JihatsukanType?: string | null,
    id?: string | null,
}) => {
    if (param.userAuthority) {
        localStorage.setItem(CommonString.UserAuthority, param.userAuthority);
    }
    if (param.userFamilyNameKanji) {
        localStorage.setItem(CommonString.UserFamilyNameKanji, param.userFamilyNameKanji);
    }
    if (param.userFirstNameKanji) {
        localStorage.setItem(CommonString.UserFirstNameKanji, param.userFirstNameKanji);
    }
    if (param.userFamilyNameHiragana) {
        localStorage.setItem(CommonString.UserFamilyNameHiragana, param.userFamilyNameHiragana);
    }
    if (param.userFirstNameHiragana) {
        localStorage.setItem(CommonString.UserFirstNameHiragana, param.userFirstNameHiragana);
    }
    if (param.userEmail) {
        localStorage.setItem(CommonString.UserEmail, param.userEmail);
    }
    if (param.userClassId) {
        localStorage.setItem(CommonString.ClassId, param.userClassId);
    }
    if (param.JihatsukanType) {
        localStorage.setItem(CommonString.JihatsukanType, param.JihatsukanType);
    }
    if (param.id) {
        localStorage.setItem(CommonString.Id, param.id);
    }
}

const resetLocalStorage = () => {
    localStorage.removeItem(CommonString.UserAuthority);
    localStorage.removeItem(CommonString.UserFamilyNameKanji);
    localStorage.removeItem(CommonString.UserFirstNameKanji);
    localStorage.removeItem(CommonString.UserFamilyNameHiragana);
    localStorage.removeItem(CommonString.UserFirstNameHiragana);
    localStorage.removeItem(CommonString.UserEmail);
    localStorage.removeItem(CommonString.ClassId);
    localStorage.removeItem(CommonString.JihatsukanType);
    localStorage.removeItem(CommonString.Id);
}

// ==============================|| ユーザー情報をアプリのどこからでもアクセスできるようするための設定 ||============================== //
const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
    // Redux
    const [stateFromStore, dispatch] = useReducer(authReducer, initialState);

    useEffect(() =>
        // ページ読み込み時に都度ユーザーのログイン有無を取得
        // ログインしていればstoreにログイン通知を送る (storeにログイン情報を保存しする理由 : アプリ内のどこからでも情報にアクセスするため)
        // storeの情報は永続化していないので、ページ再読み込み時に都度下記の処理が走る
        firebase.auth().onAuthStateChanged(async (user) => {
            const authority = localStorage.getItem(CommonString.UserAuthority)
            const userFamilyNameKanji = localStorage.getItem(CommonString.UserFamilyNameKanji)
            const userFirstNameKanji = localStorage.getItem(CommonString.UserFirstNameKanji)
            const userFamilyNameHiragana = localStorage.getItem(CommonString.UserFamilyNameHiragana)
            const userFirstNameHiragana = localStorage.getItem(CommonString.UserFirstNameHiragana)
            const userClassId = localStorage.getItem(CommonString.ClassId)
            const userEmail = localStorage.getItem(CommonString.UserEmail)
            const jihatsukanType = localStorage.getItem(CommonString.JihatsukanType)
            const id = localStorage.getItem(CommonString.Id)
            if (user && authority && userFamilyNameKanji && userFirstNameKanji && userFamilyNameHiragana && userFirstNameHiragana && userClassId && userEmail && jihatsukanType && id) {
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        isDeveloper: Authority.findFromCode(Number(authority)) == Authority.ADMIN,
                        user: {
                            userFamilyNameKanji: userFamilyNameKanji,
                            userFirstNameKanji: userFirstNameKanji,
                            userFamilyNameHiragana: userFamilyNameHiragana,
                            userFirstNameHiragana: userFirstNameHiragana,
                            userEmail: userEmail,
                            userAuthority: Number(authority),
                            classId: Number(userClassId),
                            jihatsukanType: Number(jihatsukanType),
                            id: Number(id)
                        }
                    }
                });
            } else {
                dispatch({ type: LOGOUT });
            }
        }), [dispatch])

    // ログイン処理
    const login = async (email: string, password: string) => {
        const response = await executeFirebaseApi(async () => {
            // FBへ問い合わせる
            const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
            const uid = await userCredential.user?.getIdToken(true);

            if (uid == undefined) {
                // uid取得失敗時はFBからもログアウトする
                logout()
            }
            return (await axiosClient.post<UserProfile>(ApiEndPoint.Login)).data;
        })
        setParamsToLocalStorage({
            userAuthority: response.userAuthority.toString(),
            userFamilyNameKanji: response.userFamilyNameKanji,
            userFirstNameKanji: response.userFirstNameKanji,
            userFamilyNameHiragana: response.userFamilyNameHiragana,
            userFirstNameHiragana: response.userFirstNameHiragana,
            userEmail: response.userEmail,
            userClassId: response.classId.toString(),
            JihatsukanType: response.jihatsukanType.toString(),
            id: response.id.toString()
        })
        // storeを更新
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                isDeveloper: Authority.findFromCode(Number(response.userAuthority)) == Authority.ADMIN,
                user: response
            }
        });
        return response
    }

    // ログアウト処理
    const logout = () => {
        resetLocalStorage()
        // reduxのstoreも更新
        dispatch({ type: LOGOUT });
        delete axiosClient.defaults.headers.common.Authorization;
        // FBにもログアウトを通達
        firebase.auth().signOut()
    };

    // パスワード再設定用のメール送信
    const sendPasswordResetEmail = async (email: string) => {
        await executeFirebaseApi(async () => {
            await firebase.auth().sendPasswordResetEmail(email)
        })
    }

    // メールアドレス更新前に新規メールアドレスに確認URLを送る
    const verifyBeforeUpdateEmail = async (newEmail: string) => {
        await executeFirebaseApi(async () => {
            // FBコンソールで「メール列挙保護（推奨）」のチェックを外さないとエラーになる
            await firebase.auth().currentUser?.verifyBeforeUpdateEmail(newEmail);
        })
    }

    // パスワード再設定
    const confirmPasswordReset = async (oobCode: string, newPassword: string) => {
        await executeFirebaseApi(async () => {
            await firebase.auth().confirmPasswordReset(oobCode, newPassword);
        })
    }

    // ユーザーの再認証
    const reAuthenticate = async (password: string) => {
        await executeFirebaseApi(async () => {
            const userEmail = localStorage.getItem(CommonString.UserEmail)
            if (userEmail == undefined) return
            await (await firebase.auth().currentUser?.reauthenticateWithCredential(EmailAuthProvider.credential(userEmail, password)))?.user?.getIdToken(true)
        })
    }

    // UIDの取得
    const getIdToken = async () => firebase.auth().currentUser?.getIdToken();

    // 「isInitialized」のフラグがないと初期化が終わってないのに、Providerが提供する「isLogin」などのフラグにアクセスできてしまう
    // 「isLogin」の初期値はfalseなので、ログインしてるにも関わらずfalseが一瞬だけアプリ中に伝搬してしまい
    // 一瞬だけログイン画面にリダイレクトされるような挙動になってしまうため、「isInitialized」が初期化終わるまでローディングを表示する
    if (stateFromStore.isInitialized !== undefined && !stateFromStore.isInitialized) {
        return <Loader />;
    }

    return (
        <AuthContext.Provider
            value={{
                // storeに登録したユーザーのデータもアクセスできるようにする
                ...stateFromStore,
                logout: logout,
                login: login,
                reAuthenticate: reAuthenticate,
                sendPasswordResetEmail: sendPasswordResetEmail,
                confirmPasswordReset: confirmPasswordReset,
                verifyBeforeUpdateEmail: verifyBeforeUpdateEmail,
                getIdToken: getIdToken
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContext;