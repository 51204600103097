export const PageUrl = {
    Login: {
        url: 'login',
        urlWithSlash: '/login'
    },
    Register: {
        url: 'register',
        urlWithSlash: '/register',
    },
    ForgotPassword: {
        url: 'forgot-password',
        urlWithSlash: '/forgot-password',
        authUrl: 'auth/forgot-password',
        authUrlWithSlash: '/auth/forgot-password',
    },
    CheckEmail: {
        url: 'check-mail',
        urlWithSlash: '/check-mail',
        authUrl: 'auth/check-mail',
        authUrlWithSlash: '/auth/check-mail',
    },
    ResetPassword: {
        url: 'reset-password',
        urlWithSlash: '/reset-password',
        authUrl: 'auth/reset-password',
        authUrlWithSlash: '/auth/reset-password',
    },
    DashBoard: {
        url: 'dashboard',
        urlWithSlash: '/dashboard',
    },
    Children: {
        url: 'children',
        urlWithSlash: '/children',
    },
    Plan: {
        url: 'plan',
        urlWithSlash: '/plan',
    },
    Staff: {
        url: 'staff',
        urlWithSlash: '/staff',
    },
    Class: {
        url: 'class',
        urlWithSlash: '/class',
    },
    CreatedStaffInfo: {
        url: 'staff/info',
        urlWithSlash: '/staff/info',
    },
    Profile: {
        url: 'profile',
        urlWithSlash: '/profile',
    },
    VerifyEmail: {
        url: 'verify-email',
        urlWithSlash: '/verify-email',
    },
    ChangeEmail: {
        url: 'change-email',
        urlWithSlash: '/change-email',
    },
    AuthSetting: {
        url: 'auth-setting',
        urlWithSlash: '/auth-setting',
    },
    SupportPlanList: {
        url: 'support-plan-list',
        urlWithSlash: '/support-plan-list',
    },
    CreateSupportPlan: {
        url: 'create-support-plan',
        urlWithSlash: '/create-support-plan',
    },
    ImportCSV: {
        url: 'import-csv',
        urlWithSlash: '/import-csv',
    },
    SupportPlanQuestions: {
        url: 'support-plan-questions',
        urlWithSlash: '/support-plan-questions',
    },
    CreateSupportPlanConfirm: {
        url: 'create-support-plan-confirm',
        urlWithSlash: '/create-support-plan-confirm',
    },
    SupportPlanHub: {
        url: 'support-plan-data',
        urlWithSlash: '/support-plan-data',
    },
    SupportPlanGoal: {
        url: 'support-plan-goal',
        urlWithSlash: '/support-plan-goal',
    },
    SupportPlanGoalConfirm: {
        url: 'support-plan-goal-confirm',
        urlWithSlash: '/support-plan-goal-confirm',
    },
    PreSupportPlanPdf: {
        url: 'pre-support-plan-sheet',
        urlWithSlash: '/pre-support-plan-sheet',
    },
    SupportPlanPdf: {
        url: 'support-plan-sheet',
        urlWithSlash: '/support-plan-sheet',
    },
    AssessmentPdf: {
        url: 'assessment-sheet',
        urlWithSlash: '/assessment-sheet',
    },
    Monitoring: {
        url: 'monitoring',
        urlWithSlash: '/monitoring',
    },
    SupportPlanAppendix: {
        url: 'support-plan-appendix',
        urlWithSlash: '/support-plan-appendix',
    },
    SupportPlanAppendixPdf: {
        url: 'support-plan-appendix-pdf',
        urlWithSlash: '/support-plan-appendix-pdf',
    },
    SupportPlanAppendixConfirm: {
        url: 'support-plan-appendix-confirm',
        urlWithSlash: '/support-plan-appendix-confirm',
    },
    MonitoringConfirm: {
        url: 'monitoring-confirm',
        urlWithSlash: '/monitoring-confirm',
    },
    MonitoringPdf: {
        url: 'monitoring-pdf',
        urlWithSlash: '/monitoring-pdf',
    },
    EditBasicInfo: {
        url: 'edit-basic-info',
        urlWithSlash: '/edit-basic-info',
    },
    UpdateSupportPlanInfo: {
        url: 'update-support-planInfo',
        urlWithSlash: '/update-support-planInfo',
    },
    MigrationTest: {
        url: 'migration-test',
        urlWithSlash: '/migration-test',
    },
    AssessmentResult: {
        url: 'assessment-result',
        urlWithSlash: '/assessment-result',
    },
    UpdateAlertTabMonth: {
        url: 'update-alert-month',
        urlWithSlash: '/update-alert-month',
    },
    UpdateAlertTabWeek: {
        url: 'update-alert-week',
        urlWithSlash: '/update-alert-week',
    },
    UpdateAlertTabExpired: {
        url: 'update-alert-expired',
        urlWithSlash: '/update-alert-expired',
    },
} as const;