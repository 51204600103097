export const GoalCategory = {
    1: '①健康・生活',
    2: '②運動・感覚',
    3: '③言語・コミュニケーション',
    4: '④認知・行動',
    5: '⑤社会性・人間関係',
} as const;

export type GoalCategoryType = keyof typeof GoalCategory;

export function getGoalCategoryLabel(id: GoalCategoryType): string {
    return GoalCategory[id];
}

export const SimplifiedGoalCategory = {
    1: '健康・生活',
    2: '運動・感覚',
    3: '言語・コミュ',
    4: '認知・行動',
    5: '社会性・人間関係',
} as const;

export function getSimplifiedGoalCategoryLabel(id: GoalCategoryType): string {
    return SimplifiedGoalCategory[id];
}