import axios, { InternalAxiosRequestConfig } from "axios";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import Loader from "./Loader";

export const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' },
});

type Props = {
    children: React.ReactNode;
};

const AxiosConfig = (props: Props) => {

    const { children } = props;
    const { getIdToken } = useAuth();
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(() => {
        const requestInterceptor = axiosClient.interceptors.request.use(
            async (config: InternalAxiosRequestConfig) => {
                await getIdToken().then((uid) => {
                    config.headers['Authorization'] = `Bearer ${uid}`;
                })
                return config;
            },
            (error) => { return Promise.reject(error); }
        );

        if (!isInitialized) setIsInitialized(true);

        return () => { axiosClient.interceptors.request.eject(requestInterceptor); };
    }, []);

    if (!isInitialized) return <Loader />
    return <>{children}</>;
};

export default AxiosConfig;