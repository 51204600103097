import { PageUrl } from 'const/page-url';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const UrlGuardLayout = () => {
    const location = useLocation();
    const state = location.state;
    // URL直叩き防止処理
    if (!state) {
        return <Navigate
            to={{
                pathname: PageUrl.SupportPlanList.urlWithSlash,
            }}
        />
    }
    return <Outlet />
};

export default UrlGuardLayout;