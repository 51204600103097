export enum AssessmentStatusType {
    NOT_ENTERED = 0,
    ENTERING = 1,
    ENTERED = 2,
}
export function getAssessmentStatusLabel(id: number): string {
    return AssessmentStatusLabel[id as AssessmentStatusKey];
}
const AssessmentStatusLabel = {
    0: '未入力',
    1: '入力中',
    2: '入力済',
} as const;

export type AssessmentStatusKey = keyof typeof AssessmentStatusLabel;

export enum SupportPlanStatus {
    // 基本情報入力
    AFTER_CREATION = 0,
    // アセスメント中
    UNDER_ASSESSMENT = 1,
    // アセスメント完了
    FINISHED_ASSESSMENT = 2,
    // 計画書作成中 (目標一つでも入力完了 ~ 提出ボタン押すまで)
    UNDER_CREATING_PLAN = 3,
    // 計画案提出済 (提出ボタン押すまで ~ モニタリング新規作成まで)
    FINISHED_CREATING_PLAN = 4,
    // モニタリング作成済
    FINISHED_MONITORING = 5,
}
export function getSupportPlanStatusLabel(id: number): string {
    return SupportPlanStatusLabel[id as SupportPlanStatusType];
}
const SupportPlanStatusLabel = {
    0: '基本情報入力',
    1: 'アセスメント中',
    2: 'アセスメント完了',
    3: '計画書作成中',
    4: '計画案提出済',
    5: 'モニタリング作成済',
} as const;
export type SupportPlanStatusType = keyof typeof SupportPlanStatusLabel;

export const EvaluationLabel = {
    0: '達成',
    1: '未達成',
    2: 'ほぼ達成',
} as const;
export type EvaluationKey = keyof typeof EvaluationLabel;
export function getEvaluationLabel(id: number): string {
    return EvaluationLabel[id as EvaluationKey];
}

export const NeedReviewLabel = {
    0: '有',
    1: '無',
} as const;
export type NeedReviewKey = keyof typeof NeedReviewLabel;
export function getNeedReviewLabel(id: number): string {
    return NeedReviewLabel[id as NeedReviewKey];
}

// 家族支援、移行支援等
export const OtherActivityStr = {
    1: '家族支援',
    2: '移行支援',
    3: '地域支援',
} as const;

export type OtherActivityType = keyof typeof OtherActivityStr;

// 家族支援、移行支援等
export const JihatsukanTypeStr = {
    0: 'いいえ',
    1: 'はい'
} as const;

export type JihatsukanType = keyof typeof JihatsukanTypeStr;
