import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import useApiStatusTransition from 'hooks/useApiStatusTransition';
import Error500 from 'pages/maintenance/500';
import { openSnackbar } from 'hooks/useGetSnackbar';
import { ErrorString } from 'api/error/ohana-error';
import { SnackbarProps } from 'types/snackbar';
import { PageUrl } from 'const/page-url';
import UnderConstruction from 'pages/maintenance/under-construction';

// ==============================|| AUTH GUARD ||============================== //

const ApiStatusGuard = ({ children }: GuardProps) => {
    const { is500, is503, is403, initialize } = useApiStatusTransition();
    const navigate = useNavigate();
    const location = useLocation();
    const { logout, isLoggedIn } = useAuth();

    useEffect(() => {
        // ログイン時に403エラー検知(セッション切れ)でログアウト処理を実行
        // 配下の「AuthGuard」コンポーネントにログイン画面に遷移させる処理を記述
        if (is403 && isLoggedIn) {
            logout()
            initialize()
            setTimeout(() => {
                openSnackbar({
                    open: true,
                    message: ErrorString.UnAuthorized,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                } as SnackbarProps);
            }, 500);
        }
        // rootにアクセス時はログイン画面にリダイレクトさせる
        if (location.pathname == '/') {
            navigate(PageUrl.Login.urlWithSlash, {
                state: {
                    from: ''
                }
            });
        }
    }, [is403, navigate, location, isLoggedIn]);

    if (is500) {
        return <Error500 />
    }

    if (is503) {
        return <UnderConstruction />
    }

    return children;
};

export default ApiStatusGuard;
