import { useMemo } from 'react';

// material-ui
import { Box, Button, CardContent, Divider, Modal, Stack, Typography } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import SimpleBar from 'components/third-party/SimpleBar';
import CircularWithPath from 'components/@extended/progress/CircularWithPath';
import { useGetGoals } from 'api/support-plan-goal';
import { SupportPlanData, SupportPlanDataGoalResponse } from 'types/support-plan';
import { GoalCategory, GoalCategoryType, getGoalCategoryLabel } from 'const/goal-category';
import InfoCard from 'sections/card/InfoCard';
import { S3UrlPdf } from 'const/aws';
import React from 'react';

interface Props {
    open: boolean;
    modalToggler: (state: boolean) => void;
    supportPlanData: SupportPlanData
}

// ==============================|| 目標設定内容確認  ||============================== //

const TargetCheckModal = ({ open, modalToggler, supportPlanData }: Props) => {
    const { supportPlanGoals, supportPlanGoalsLoading } = useGetGoals(supportPlanData.id);
    const closeModal = () => modalToggler(false);

    const content = useMemo(
        () => !supportPlanGoalsLoading && supportPlanGoals != undefined && <ModalContent closeModal={closeModal} supportPlanGoals={supportPlanGoals} />,
        // eslint-disable-next-line
        [supportPlanGoalsLoading, supportPlanGoals]
    );

    return (
        <>
            {open && (
                <Modal
                    open={open}
                    onClose={closeModal}
                    aria-labelledby="modal-check-target-label"
                    aria-describedby="modal-check-target-description"
                    sx={{
                        '& .MuiPaper-root:focus': {
                            outline: 'none'
                        }
                    }}
                >
                    <MainCard
                        sx={{ width: `calc(100% - 48px)`, minWidth: 340, maxWidth: 880, height: 'auto', maxHeight: 'calc(100vh - 48px)' }}
                        modal
                        content={false}
                    >
                        <SimpleBar
                            sx={{
                                maxHeight: `calc(100vh - 48px)`,
                                '& .simplebar-content': {
                                    display: 'flex',
                                    flexDirection: 'column'
                                }
                            }}
                        >
                            {supportPlanGoalsLoading || supportPlanGoals == undefined ? (
                                <Box sx={{ p: 5 }}>
                                    <Stack direction="row" justifyContent="center">
                                        <CircularWithPath />
                                    </Stack>
                                </Box>
                            ) : (content)}
                        </SimpleBar>
                    </MainCard>
                </Modal>
            )}
        </>
    );
};

export default TargetCheckModal;

const ModalContent = ({ closeModal, supportPlanGoals }: { closeModal: () => void, supportPlanGoals: SupportPlanDataGoalResponse }) => {

    const formatAchievementTerm = (term: number | undefined | null) => {
        return term != null ? `${term}ヶ月` : '未設定';
    };

    // 台本クリック時
    const handleButtonDClicked = (url: string | undefined) => {
        const showUrl = S3UrlPdf + url + ".pdf"
        if (url) {
            window.open(showUrl, '_blank');
        }
    };
    // スタッフ用クリック時
    const handleButtonSClicked = (url: string | undefined) => {
        const showUrl = S3UrlPdf + url + ".pdf"
        if (url) {
            window.open(showUrl, '_blank');
        }
    };
    // 子ども用クリック時
    const handleButtonKClicked = (url: string | undefined) => {
        const showUrl = S3UrlPdf + url + ".pdf"
        if (url) {
            window.open(showUrl, '_blank');
        }
    };

    const renderScriptButtons = (activityUrlD?: string, activityUrlS?: string, activityUrlK?: string) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
                variant="outlined"
                size="small"
                onClick={() => handleButtonDClicked(activityUrlD)}
                sx={{ fontSize: '0.7rem' }}
                disabled={!activityUrlD}
            >
                台本
            </Button>
            <Button
                variant="outlined"
                size="small"
                onClick={() => handleButtonSClicked(activityUrlS)}
                sx={{ fontSize: '0.7rem' }}
                disabled={!activityUrlS}
            >
                スタッフ用
            </Button>
            <Button
                variant="outlined"
                size="small"
                onClick={() => handleButtonKClicked(activityUrlK)}
                sx={{ fontSize: '0.7rem' }}
                disabled={!activityUrlK}
            >
                こども用
            </Button>
        </Box>
    );

    return <>
        <MainCard>
            <CardContent>
                <Typography variant='h3' id="modal-modal-description">設定内容確認</Typography>
                {Object.keys(GoalCategory).map((key) => {
                    const category = parseInt(key) as GoalCategoryType;
                    const info = [
                        { key: '短期目標', value: supportPlanGoals[category]?.shortTermGoal?.shortTermGoalName ?? '未設定' },
                        { key: '長期目標カテゴリ', value: supportPlanGoals[category]?.longTermGoalCategory ?? '未設定' },
                        { key: '長期目標', value: supportPlanGoals[category]?.longTermGoal?.longTermGoalName ?? '未設定' },
                        {
                            key: '優先課題', value: (
                                <React.Fragment>
                                    {supportPlanGoals?.[category]?.activity?.activityName ?? '未設定'}
                                    (達成時期: {formatAchievementTerm(supportPlanGoals?.[category]?.achieveTerm)})
                                    {renderScriptButtons(supportPlanGoals?.[category]?.activity?.activityUrlD, supportPlanGoals?.[category]?.activity?.activityUrlS, supportPlanGoals?.[category]?.activity?.activityUrlK)}
                                </React.Fragment>
                            )
                        },
                        { key: 'その他留意事項', value: supportPlanGoals?.[category]?.note ?? '未設定' },
                    ];


                    return (
                        <InfoCard key={category} title={getGoalCategoryLabel(category)} info={info} keyWidth={3} />
                    );
                })}
            </CardContent>
            <Divider />
            <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ pt: 2 }}>
                <Button color="error" onClick={closeModal}>
                    閉じる
                </Button>
            </Stack>
        </MainCard>
    </>
}
