import { CalculatorOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Typography } from "@mui/material";
import { useGetSupportPlanAppendix } from "api/support-plan";
import Loader from "components/Loader";
import MainCard from "components/MainCard";
import { PageUrl } from "const/page-url";
import { SUPPORT_PLAN_HUB_SESSION_STRING } from "pages/support-plan/base";
import React from "react";
import { useNavigate } from "react-router";
import { SupportPlanData } from "types/support-plan";

function AppendixInfo(data: SupportPlanData) {
    const navigate = useNavigate();
    const { supportPlanAppendix, supportPlanAppendixLoading } = useGetSupportPlanAppendix(data.id);

    return <React.Fragment>
        {supportPlanAppendixLoading && <Loader />}
        <Typography variant="h2">個別支援計画別表</Typography>
        <MainCard content={false} sx={{ mt: 1.5, p: 3 }}>
            <Button
                variant="contained"
                size="large"
                sx={{ m: 1.5 }}
                startIcon={<EditOutlined />}
                onClick={() => {
                    navigate(PageUrl.SupportPlanAppendix.urlWithSlash, { state: data });
                }}>
                {supportPlanAppendix && <>個別支援計画別表編集</>}
                {supportPlanAppendix == undefined && <>個別支援計画別表新規作成</>}
            </Button>
            {supportPlanAppendix?.appendixCreateDate && <Button
                variant="contained"
                size="large"
                sx={{ m: 1.5 }}
                startIcon={<CalculatorOutlined />}
                onClick={() => {
                    localStorage.setItem(SUPPORT_PLAN_HUB_SESSION_STRING, JSON.stringify(data));
                    window.open(PageUrl.SupportPlanAppendixPdf.urlWithSlash, '_blank', 'rel=noopener noreferrer')
                }}
            >
                個別支援計画別表出力
            </Button>}
        </MainCard>
    </React.Fragment>
}

export default AppendixInfo;