import { Outlet } from 'react-router-dom';
import DeveloperGuard from 'utils/route-guard/DeveloperGuard';

// ==============================|| LAYOUT - AUTH ||============================== //

const DeveloperAuthorizedLayout = () => (
  <DeveloperGuard>
    <Outlet />
  </DeveloperGuard>
);

export default DeveloperAuthorizedLayout;
