import { CalculatorOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Typography } from "@mui/material";
import { useDeleteMonitoring, useGetMonitoring } from "api/monitoring";
import { useUpdateSupportPlan } from "api/support-plan";
import Loader from "components/Loader";
import MainCard from "components/MainCard";
import { PageUrl } from "const/page-url";
import { SupportPlanStatus } from "const/support-plan";
import { SUPPORT_PLAN_HUB_SESSION_STRING, SupportPlanHubProps } from "pages/support-plan/base";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { SnackbarProps } from 'types/snackbar';
import { openSnackbar } from 'hooks/useGetSnackbar';
import BasicModal from "components/modal/BasicModal";
import usePreventDoubleClick from "hooks/usePreventDoubleClick";

function MonitoringInfo({ data, setData }: SupportPlanHubProps) {
    const navigate = useNavigate();
    const { data: monitoring, isLoading } = useGetMonitoring(data.id)
    const { deleteMonitoring } = useDeleteMonitoring()
    const { updateSupportPlan, isUpdating } = useUpdateSupportPlan();
    const { handleClick, isExecuting } = usePreventDoubleClick(async () => {
        const newData = {
            ...data,
            supportPlanStatus: SupportPlanStatus.FINISHED_CREATING_PLAN
        }
        await updateSupportPlan(newData)
        await deleteMonitoring(data.id)
        openSnackbar({
            open: true,
            message: "モニタリング内容を削除しました",
            variant: 'alert',
            alert: {
                color: 'success'
            }
        } as SnackbarProps);
        setData(newData)
        // ダイアログを閉じる
        setModalOpen(false);
    })
    const [modalOpen, setModalOpen] = useState(false);

    if (monitoring == undefined || isLoading || isUpdating || isExecuting) return <Loader />
    return <React.Fragment>
        <Typography variant="h2">モニタリング</Typography>
        <MainCard content={false} sx={{ mt: 1.5, p: 3 }}>
            {data.isCurrentSupportPlan && <Button
                disabled={data.supportPlanStatus < SupportPlanStatus.FINISHED_CREATING_PLAN}
                variant="contained"
                size="large"
                sx={{ m: 1.5 }}
                startIcon={<EditOutlined />}
                onClick={() => {
                    navigate(PageUrl.Monitoring.urlWithSlash, { state: data });
                }}>
                {data.supportPlanStatus <= SupportPlanStatus.FINISHED_CREATING_PLAN && <>モニタリング新規作成</>}
                {data.supportPlanStatus == SupportPlanStatus.FINISHED_MONITORING && <>モニタリング編集</>}
            </Button>}
            {data.supportPlanStatus == SupportPlanStatus.FINISHED_MONITORING && <React.Fragment> <Button
                variant="contained"
                size="large"
                sx={{ m: 1.5 }}
                startIcon={<CalculatorOutlined />}
                onClick={() => {
                    localStorage.setItem(SUPPORT_PLAN_HUB_SESSION_STRING, JSON.stringify(data));
                    window.open(PageUrl.MonitoringPdf.urlWithSlash, '_blank', 'rel=noopener noreferrer')
                }}>
                モニタリング出力
            </Button>
                {data.isCurrentSupportPlan && <Button
                    variant="contained"
                    size="large"
                    color="error"
                    sx={{ m: 1.5 }}
                    startIcon={<DeleteOutlined />}
                    onClick={async () => { setModalOpen(true) }}>
                    モニタリング内容クリア
                </Button>}
            </React.Fragment>
            }
        </MainCard>
        <BasicModal
            open={modalOpen}
            setOpen={setModalOpen}
            title={`モニタリング内容を削除します`}
            description={`この操作は取り消せません。実行してもよろしいですか?`}
            submitButtonString='続行する'
            onCLickPositiveButton={handleClick} />
    </React.Fragment>
}

export default MonitoringInfo;