import { SESSION_KEYS } from "const/session-keys";

// 保存
export const saveToSessionStorage = (key: string, data: any) => {
    sessionStorage.setItem(key, JSON.stringify(data));
};

// 取得
export const loadFromSessionStorage = (key: string) => {
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
};

// 削除
export const clearSessionStorage = (key: string) => {
    sessionStorage.removeItem(key);
};

// 全てのキーを削除
export const clearAllSessionStorage = () => {
    Object.values(SESSION_KEYS).forEach(key => {
        sessionStorage.removeItem(key);
    });
};