import { Box, Button, Grid, LinearProgress, Stack, Typography, alpha, useTheme } from "@mui/material";
import { useGetAssessmentStatus } from "api/assessment";
import Loader from "components/Loader";
import TitleButtonCard from "components/cards/TitleButtonCard";
import { when } from "utils/when";
import { AssessmentCategoryStatus } from "types/support-plan";
import MainCard from "components/MainCard";
import { EditOutlined, CheckOutlined, BarChartOutlined, CalculatorOutlined } from "@ant-design/icons";
import { PageUrl } from "const/page-url";
import { useNavigate } from "react-router";
import { SupportPlanWithTargetScrollCategory } from "../Questions";
import Reader from 'assets/images/analytics/reader.svg';
import { AssessmentStatusType, SupportPlanStatus, getAssessmentStatusLabel } from "const/support-plan";
import { useUpdateSupportPlan } from "api/support-plan";
import { SnackbarProps } from 'types/snackbar';
import { openSnackbar } from 'hooks/useGetSnackbar';
import { SUPPORT_PLAN_HUB_SESSION_STRING, SupportPlanHubProps } from "pages/support-plan/base";
import React from "react";
import usePreventDoubleClick from "hooks/usePreventDoubleClick";

function AssessmentStatus({ data, setData }: SupportPlanHubProps) {

    const theme = useTheme();
    const navigate = useNavigate();
    const { assessmentStatus, assessmentStatusLoading } = useGetAssessmentStatus(data.systemClassificationId, data.id);
    const { updateSupportPlan, isUpdating: isSupportPlanUpdating } = useUpdateSupportPlan();
    const { handleClick: updateAssessmentStatus, isExecuting } = usePreventDoubleClick(async (newStatus: SupportPlanStatus, message: string) => {
        const response = await updateSupportPlan({
            ...data,
            supportPlanStatus: newStatus
        })
        openSnackbar({
            open: true,
            message: message,
            variant: 'alert',
            alert: {
                color: 'success'
            }
        } as SnackbarProps);
        setData(response)
    });

    const getAssessmentStatusColor = (status: AssessmentCategoryStatus) => {
        return when(status)
            .on(
                (value) => value.status == AssessmentStatusType.NOT_ENTERED,
                () => theme.palette.grey[400]
            )
            .on(
                (value) => value.status == AssessmentStatusType.ENTERING,
                () => alpha(theme.palette.info.light, 0.4)
            )
            .on(
                (value) => value.status == AssessmentStatusType.ENTERED,
                () => alpha("#068e44", 0.4)
            )
            .otherwise(
                () => theme.palette.grey[400]
            )
    }

    const getLabelVariant = (category: string) => {
        if (category.length > 10) {
            return "body2"
        } else {
            return "h5"
        }
    }

    const EditButtonLabel = (assessmentStatus: AssessmentCategoryStatus[]) => {
        if (data.supportPlanStatus == SupportPlanStatus.AFTER_CREATION || assessmentStatus.filter((item) => {
            return item.status == AssessmentStatusType.ENTERING || item.status == AssessmentStatusType.ENTERED
        }).length == 0) {
            return "アセスメント新規作成"
        }
        return "アセスメント編集"
    }

    if (assessmentStatus == undefined) {
        return <Loader />
    }

    const isAllQuestionsAnswered = (assessmentStatus: AssessmentCategoryStatus[]) => {
        return assessmentStatus.filter((item) => {
            return item.status == AssessmentStatusType.ENTERING || item.status == AssessmentStatusType.NOT_ENTERED
        }).length == 0
    }

    return <React.Fragment>
        {(assessmentStatusLoading || isSupportPlanUpdating || isExecuting) && <Loader />}
        <Typography variant="h2">アセスメント</Typography>
        {(isAllQuestionsAnswered(assessmentStatus) && data.supportPlanStatus == SupportPlanStatus.FINISHED_ASSESSMENT) && <Grid container>
            <Grid
                item
                xs={12}
                sm={8}
                sx={{
                    bgcolor: `${theme.palette.primary.main}`,
                    position: 'relative',
                    p: 2.75,
                    borderRadius: { xs: 2, sm: '8px' },
                    overflow: 'hidden',
                    mt: 3,
                    mb: 3
                }}
            >
                <Stack>
                    <Typography variant="h5" color="white">
                        アセスメント入力完了
                    </Typography>
                    <Typography color={theme.palette.grey[0]} variant="caption" sx={{ maxWidth: '55%', pt: 1 }}>
                        お疲れ様でした。
                    </Typography>
                    <Typography variant="h4" color="white" sx={{ pt: 2, pb: 1, zIndex: 1 }}>
                        100% Completed
                    </Typography>
                    <Box sx={{ maxWidth: '60%' }}>
                        <LinearProgress variant="determinate" color="success" value={100} />
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: -7,
                            right: 0,
                        }}
                    >
                        <img alt="reder" src={Reader} />
                    </Box>
                </Stack>
            </Grid>
        </Grid>}
        <MainCard content={false} sx={{ mt: 1.5, p: 3 }}>
            {(data.supportPlanStatus == SupportPlanStatus.FINISHED_ASSESSMENT) &&
                <React.Fragment>
                    {data.isCurrentSupportPlan && <Button
                        variant="contained"
                        size="large"
                        sx={{ m: 1.5 }}
                        disabled={data.supportPlanStatus >= SupportPlanStatus.FINISHED_CREATING_PLAN}
                        color="error"
                        startIcon={<EditOutlined />}
                        onClick={async () => {
                            // 個別支援計画のステータスを「アセスメント中」に戻す
                            updateAssessmentStatus(SupportPlanStatus.UNDER_ASSESSMENT, 'アセスメント編集にステータスを戻します')
                        }}>
                        アセスメントシート修正
                    </Button>}
                </React.Fragment>
            }
            {(data.supportPlanStatus >= SupportPlanStatus.FINISHED_ASSESSMENT) && <Button
                variant="contained"
                size="large"
                sx={{ m: 1.5 }}
                disabled={data.supportPlanStatus < SupportPlanStatus.FINISHED_ASSESSMENT}
                startIcon={<BarChartOutlined />}
                onClick={() => {
                    navigate(PageUrl.AssessmentResult.urlWithSlash, { state: data });
                }}>
                診断結果を確認する
            </Button>}
            {data.supportPlanStatus == SupportPlanStatus.UNDER_ASSESSMENT &&
                <React.Fragment>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{ m: 1.5 }}
                        startIcon={<EditOutlined />}
                        onClick={() => { navigate(PageUrl.SupportPlanQuestions.urlWithSlash, { state: data }); }}>
                        {EditButtonLabel(assessmentStatus)}
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{ m: 1.5 }}
                        color="error"
                        startIcon={<CheckOutlined />}
                        disabled={!(isAllQuestionsAnswered(assessmentStatus) && data.supportPlanStatus == SupportPlanStatus.UNDER_ASSESSMENT)}
                        onClick={async () => {
                            // 個別支援計画のステータスを「目標設定中」に変える
                            updateAssessmentStatus(SupportPlanStatus.FINISHED_ASSESSMENT, 'アセスメントを確定しました')
                        }}>
                        アセスメントを確定する
                    </Button>
                </React.Fragment>
            }
            {data.supportPlanStatus == SupportPlanStatus.AFTER_CREATION &&
                <React.Fragment>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{ m: 1.5 }}
                        startIcon={<EditOutlined />}
                        onClick={() => { navigate(PageUrl.SupportPlanQuestions.urlWithSlash, { state: data }); }}>
                        {EditButtonLabel(assessmentStatus)}
                    </Button>
                </React.Fragment>
            }
            <Button
                variant="contained"
                size="large"
                sx={{ m: 1.5 }}
                startIcon={<CalculatorOutlined />}
                onClick={() => {
                    localStorage.setItem(SUPPORT_PLAN_HUB_SESSION_STRING, JSON.stringify(data));
                    window.open(PageUrl.AssessmentPdf.urlWithSlash, '_blank', 'rel=noopener noreferrer')
                }}>
                出力する
            </Button>
        </MainCard>
        <React.Fragment>
            {(data.supportPlanStatus == SupportPlanStatus.UNDER_ASSESSMENT) && <React.Fragment>
                <Typography variant="h4" sx={{ mt: 2 }}>アセスメント入力状況</Typography>
                <Grid container justifyContent="center">
                    {assessmentStatus.map((item) => {
                        return <Grid item xs={12} lg={3} sm={5} sx={{ m: 2 }}>
                            <TitleButtonCard
                                primary={item.category}
                                secondary={getAssessmentStatusLabel(item.status)}
                                buttonLabel="編集する"
                                color={getAssessmentStatusColor(item)}
                                primaryLabelVariant={getLabelVariant(item.category)}
                                onClickButton={() => {
                                    navigate(PageUrl.SupportPlanQuestions.urlWithSlash, { state: { ...data, targetCategory: item.category } as SupportPlanWithTargetScrollCategory });
                                }} />
                        </Grid>
                    })}
                </Grid>
            </React.Fragment>}
        </React.Fragment>
    </React.Fragment>
}
export default AssessmentStatus;