import { useState, useCallback } from 'react';

// 非同期関数の型定義
type AsyncFunction = (...args: any[]) => Promise<void>;

//////////////// 二度押し防止のためのカスタムフック ////////////////
const usePreventDoubleClick = (asyncFunction: AsyncFunction) => {
  // 処理中かどうかを管理するステート
  const [isExecuting, setIsExecuting] = useState<boolean>(false);

  // 二度押しを防ぐためのラップ関数
  const handleClick = useCallback(async (...args: any[]) => {
    if (isExecuting) return; // 処理が実行中の場合は何もしない

    setIsExecuting(true); // 処理の開始を設定
    try {
      await asyncFunction(...args); // 非同期関数を実行
    } finally {
      setIsExecuting(false); // 処理の終了を設定
    }
  }, [asyncFunction, isExecuting]);

  return { handleClick, isExecuting };
};

export default usePreventDoubleClick;
