import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';

import { PageUrl } from 'const/page-url';
import DashboardLayout from 'layout/Dashboard';
import PagesLayout from 'layout/Pages';
import AuthorizedLayout from 'layout/Authorized';
import UrlGuardLayout from 'layout/UrlGuard';
import PdfGuardLayout from 'layout/PdfGuard';
import MaintenanceGuard from 'layout/MaintenanceGuard';
import { Navigate } from 'react-router';

// render - pages
const Dashboard = Loadable(lazy(() => import('pages/dashboard/')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const ResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const VerifyEmail = Loadable(lazy(() => import('pages/auth/verify-email')));
const ChangeEmail = Loadable(lazy(() => import('pages/auth/change-email')));
const Children = Loadable(lazy(() => import('pages/child')));
const Staff = Loadable(lazy(() => import('pages/staff')));
const CreatedStaffInfo = Loadable(lazy(() => import('pages/staff/created-staff-info')));
const Profile = Loadable(lazy(() => import('pages/profile/')));
const Classes = Loadable(lazy(() => import('pages/class/')));
const SupportPlanList = Loadable(lazy(() => import('pages/support-plan/list/')));
const CreateSupportPlan = Loadable(lazy(() => import('pages/support-plan/create/')));
const SupportPlanQuestions = Loadable(lazy(() => import('pages/support-plan/questions/')));
const CreateSupportPlanConfirm = Loadable(lazy(() => import('pages/support-plan/create/confirm')));
const SupportPlanHub = Loadable(lazy(() => import('pages/support-plan/base/')));
const SupportPlanGoal = Loadable(lazy(() => import('pages/support-plan/goal/')));
const SupportPlanGoalConfirm = Loadable(lazy(() => import('pages/support-plan/goal/confirm')));
const PreSupportPlanPdf = Loadable(lazy(() => import('pages/support-plan/pdf/pre-support-plan-pdf')));
const SupportPlanPdf = Loadable(lazy(() => import('pages/support-plan/pdf/support-plan-pdf')));
const AssessmentPdf = Loadable(lazy(() => import('pages/support-plan/pdf/assessment-pdf')));
const SupportPlanAppendixPdf = Loadable(lazy(() => import('pages/support-plan/pdf/appendix-pdf')));
const MonitoringPdf = Loadable(lazy(() => import('pages/support-plan/pdf/monitoring-pdf')));
const Monitoring = Loadable(lazy(() => import('pages/support-plan/monitoring')));
const SupportPlanAppendix = Loadable(lazy(() => import('pages/support-plan/appendix')));
const SupportPlanAppendixConfirm = Loadable(lazy(() => import('pages/support-plan/appendix/confirm')));
const ConfirmMonitoring = Loadable(lazy(() => import('pages/support-plan/monitoring/confirm')));
const EditBasicInfo = Loadable(lazy(() => import('pages/support-plan/create/edit')));
const UpdateSupportPlanInfo = Loadable(lazy(() => import('pages/support-plan/create/update')));
const AssessmentResult = Loadable(lazy(() => import('pages/support-plan/assessment/result')));
const UpdateAlertTabMonth = Loadable(lazy(() => import('sections/dashboard/alert/tab/TabMonth')));
const UpdateAlertTabWeek = Loadable(lazy(() => import('sections/dashboard/alert/tab/TabWeek')));
const UpdateAlertTabExpired = Loadable(lazy(() => import('sections/dashboard/alert/tab/TabExpired')));

// ==============================|| MAIN ROUTING ||============================== //

const AuthorizedRoutes = {
  path: '/',
  element: <AuthorizedLayout />,
  children: [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: PageUrl.DashBoard.url,
          element: <Dashboard />,
          children: [
            {
              path: '',
              element: <Navigate to={PageUrl.UpdateAlertTabMonth.url} replace />,
            },
            {
              path: PageUrl.UpdateAlertTabMonth.url,
              element: <UpdateAlertTabMonth />,
            },
            {
              path: PageUrl.UpdateAlertTabWeek.url,
              element: <UpdateAlertTabWeek />,
            },
            {
              path: PageUrl.UpdateAlertTabExpired.url,
              element: <UpdateAlertTabExpired />,
            },
          ]
        },
        {
          path: PageUrl.Children.url,
          element: <Children />
        },
        {
          path: PageUrl.Staff.url,
          element: <Staff />
        },
        {
          path: PageUrl.CreatedStaffInfo.url,
          element: <CreatedStaffInfo />
        },
        {
          path: PageUrl.Class.url,
          element: <Classes />
        },
        {
          path: PageUrl.SupportPlanList.url,
          element: <SupportPlanList />
        },
        {
          path: PageUrl.CreateSupportPlan.url,
          element: <CreateSupportPlan />
        },
        {
          path: PageUrl.SupportPlanGoalConfirm.url,
          element: <SupportPlanGoalConfirm />
        },
        {
          path: PageUrl.CreateSupportPlanConfirm.url,
          element: <CreateSupportPlanConfirm />
        },
        {
          path: PageUrl.SupportPlanAppendix.url,
          element: <SupportPlanAppendix />
        },
        {
          path: PageUrl.SupportPlanAppendixConfirm.url,
          element: <SupportPlanAppendixConfirm />
        },
        {
          path: PageUrl.AssessmentResult.url,
          element: <AssessmentResult />
        },
        {
          path: '/',
          element: <UrlGuardLayout />,
          children: [
            {
              path: PageUrl.SupportPlanHub.url,
              element: <SupportPlanHub />
            },
            {
              path: PageUrl.SupportPlanQuestions.url,
              element: <SupportPlanQuestions />
            },
            {
              path: PageUrl.SupportPlanGoal.url,
              element: <SupportPlanGoal />
            },
            {
              path: PageUrl.Monitoring.url,
              element: <Monitoring />
            },
            {
              path: PageUrl.MonitoringConfirm.url,
              element: <ConfirmMonitoring />
            },
            {
              path: PageUrl.EditBasicInfo.url,
              element: <EditBasicInfo />
            },
            {
              path: PageUrl.UpdateSupportPlanInfo.url,
              element: <UpdateSupportPlanInfo />
            },
          ]
        },
        {
          path: '/',
          element: <MaintenanceGuard />,
          children: [
            {
              path: PageUrl.Profile.url,
              element: <Profile />
            },
          ]
        },
      ]
    },
    // 以下、認証関連の画面
    // 認証関連の画面はドロワーやヘッダを表示させないので、「DashboardLayout」の配下には入れない MaintenanceGuard
    {
      path: '/',
      element: <PagesLayout />,
      children: [
        {
          path: '/',
          element: <MaintenanceGuard />,
          children: [
            {
              path: PageUrl.ForgotPassword.authUrl,
              element: <AuthForgotPassword />
            },
            {
              path: PageUrl.CheckEmail.authUrl,
              element: <AuthCheckMail />
            },
            {
              path: PageUrl.ResetPassword.authUrl,
              element: <ResetPassword />
            },
            {
              path: PageUrl.VerifyEmail.url,
              element: <VerifyEmail />
            },
            {
              path: PageUrl.ChangeEmail.url,
              element: <ChangeEmail />
            },
          ]
        },
      ]
    },
    // PDF関連
    {
      path: '/',
      element: <PdfGuardLayout />,
      children: [
        {
          path: PageUrl.PreSupportPlanPdf.url,
          element: <PreSupportPlanPdf />
        },
        {
          path: PageUrl.SupportPlanPdf.url,
          element: <SupportPlanPdf />
        },
        {
          path: PageUrl.AssessmentPdf.url,
          element: <AssessmentPdf />
        },
        {
          path: PageUrl.MonitoringPdf.url,
          element: <MonitoringPdf />
        },
        {
          path: PageUrl.SupportPlanAppendixPdf.url,
          element: <SupportPlanAppendixPdf />
        },
      ]
    }
  ]
};
export default AuthorizedRoutes;