import { SchoolCategory, ServiceCategory, TimeDIvision } from "types/child"
import { when } from "./when"

// 1) 児童発達支援
// 2) 放課後デイサービス
// 3) 学校
export const getServiceCategoryNumber = (serviceCategory: ServiceCategory) => {
    return when(serviceCategory)
        .on(
            (value) => value == ServiceCategory.CHILD_DEVELOPMENT_SUPPORT,
            () => 1
        )
        .on(
            (value) => value == ServiceCategory.AFTER_SCHOOL_DAY_SERVICE,
            () => 2
        )
        .on(
            (value) => value == ServiceCategory.SCHOOL,
            () => 3
        )
        .otherwise(() => -1);
}

export const getServiceCategory = (serviceCategoryNumber: number) => {
    return when(serviceCategoryNumber)
        .on(
            (value) => value == 1,
            () => ServiceCategory.CHILD_DEVELOPMENT_SUPPORT
        )
        .on(
            (value) => value == 2,
            () => ServiceCategory.AFTER_SCHOOL_DAY_SERVICE
        )
        .on(
            (value) => value == 3,
            () => ServiceCategory.SCHOOL
        )
        .otherwise(() => ServiceCategory.CHILD_DEVELOPMENT_SUPPORT);
}

// 通学区分
// 1) 未就学児童
// 2) 地域小学校
// 3) 地域中・高学校
// 4) 特別支援小学校
// 5) 特別支援中・高学校
export const getSchoolCategoryNumber = (serviceCategory: SchoolCategory) => {
    return when(serviceCategory)
        .on(
            (value) => value == SchoolCategory.PRESCHOOLER,
            () => 1
        )
        .on(
            (value) => value == SchoolCategory.NORMAL_SCHOOL,
            () => 2
        )
        .on(
            (value) => value == SchoolCategory.NORMAL_JUNIOR_HIGH_SCHOOL,
            () => 3
        )
        .on(
            (value) => value == SchoolCategory.SPECIAL_CARE_SCHOOL,
            () => 4
        )
        .on(
            (value) => value == SchoolCategory.SPECIAL_CARE_JUNIOR_HIGH_SCHOOL,
            () => 5
        )
        .otherwise(() => -1);
}

export const getSchoolCategory = (serviceCategoryNumber: number) => {
    return when(serviceCategoryNumber)
        .on(
            (value) => value ==    1,
            () => SchoolCategory.PRESCHOOLER
        )
        .on(
            (value) => value == 2,
            () => SchoolCategory.NORMAL_SCHOOL
        )
        .on(
            (value) => value == 3,
            () => SchoolCategory.NORMAL_JUNIOR_HIGH_SCHOOL
        )
        .on(
            (value) => value == 4,
            () => SchoolCategory.SPECIAL_CARE_SCHOOL
        )
        .on(
            (value) => value == 5,
            () => SchoolCategory.SPECIAL_CARE_JUNIOR_HIGH_SCHOOL
        )
        .otherwise(() => SchoolCategory.PRESCHOOLER);
}

export const getTimeDivisionNumber = (timeDIvision: TimeDIvision) => {
    return when(timeDIvision)
        .on(
            (value) => value == TimeDIvision.ONE,
            () => 1
        )
        .on(
            (value) => value == TimeDIvision.TWO,
            () => 2
        )
        .on(
            (value) => value == TimeDIvision.THREE,
            () => 3
        )
        .otherwise(() => -1);
}

export const getTimeDivision = (timeDIvision: number) => {
    return when(timeDIvision)
        .on(
            (value) => value == 1,
            () => TimeDIvision.ONE,
        )
        .on(
            (value) => value == 2,
            () => TimeDIvision.TWO,
        )
        .on(
            (value) => value == 3,
            () => TimeDIvision.THREE,
        )
        .otherwise(() => -1);
}
