import { Grid } from "@mui/material"
import { useLocation } from "react-router";
import TargetSetting from "sections/support-plan/base/TargetSetting";
import PreSupportPlan from "sections/support-plan/base/PreSupportPlan";
import SupportPlan from "sections/support-plan/base/SupportPlan";
import BasicInfo from "sections/support-plan/base/BasicInfo";
import MonitoringInfo from "sections/support-plan/base/MonitoringInfo";
import AssessmentStatus from "sections/support-plan/base/AssessmentStatus";
import { useEffect, useState } from "react";
import { SupportPlanData } from "types/support-plan";
import { clearSessionStorage } from "hooks/useSessionStorage";
import { SESSION_KEYS } from "const/session-keys";
import AppendixInfo from "sections/support-plan/base/AppendixInfo";
import { useGetSupportPlan } from "api/support-plan";
import UpdateSupportPlan from "sections/support-plan/base/UpdateSupportPlan";
import React from "react";
import { setTitleAndDescription } from "utils/seo-helper";
import DeleteSupportPlan from "sections/support-plan/base/DeleteSupportPlan";
import { useGetMyProfile } from "api/staff";
import Loader from "components/Loader";
import { Authority } from "enum/authority";

export type SupportPlanHubProps = {
    data: SupportPlanData,
    setData: (response: SupportPlanData) => void
}

export const SUPPORT_PLAN_HUB_SESSION_STRING = "SUPPORT_PLAN_HUB_SESSION_STRING"

// 個別支援計画hub
const SupportPlanBase = () => {
    useEffect(() => {
        setTitleAndDescription("OHANA | 個別支援計画Hub", "")
    }, [])
    const { userInfo, isLoading: isUserInfoLoading } = useGetMyProfile()
    const location = useLocation();
    const supportPlanInfoState = location.state;
    const supportPlanInfo = supportPlanInfoState as SupportPlanData
    const { data } = useGetSupportPlan(supportPlanInfo.id)
    const [supportPlanData, setSupportPlanData] = useState(supportPlanInfo)
    const updateSupportPlan = (response: SupportPlanData) => setSupportPlanData(response)

    useEffect(() => {
        clearSessionStorage(SESSION_KEYS.GOAL_FORM_INPUT);
        clearSessionStorage(SESSION_KEYS.SUPPORT_TIME_FORM_INPUT);
        clearSessionStorage(SESSION_KEYS.MONITORING_INPUT);
        localStorage.removeItem(SUPPORT_PLAN_HUB_SESSION_STRING);
    }, [])
    useEffect(() => {
        if (data) {
            updateSupportPlan(data)
        }
    }, [data])

    useEffect(() => {
        setTimeout(function () {
            window.scrollTo({
                top: 0,
                left: 0,
            });
        }, 100);
    }, []);

    if (isUserInfoLoading) return <Loader />

    return <React.Fragment>
        <Grid container spacing={3}>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
                <BasicInfo data={supportPlanData} setData={updateSupportPlan} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
                <AssessmentStatus data={supportPlanData} setData={updateSupportPlan} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
                <TargetSetting data={supportPlanData} setData={updateSupportPlan} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
                <PreSupportPlan data={supportPlanData} setData={updateSupportPlan} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
                <SupportPlan {...supportPlanData} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
                <AppendixInfo {...supportPlanData} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
                <MonitoringInfo data={supportPlanData} setData={updateSupportPlan} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.5 }}>
                <UpdateSupportPlan data={supportPlanData} setData={updateSupportPlan} />
            </Grid>
            {(Authority.findFromCode(userInfo?.userAuthority) == Authority.LEADER
                || Authority.findFromCode(userInfo?.userAuthority) == Authority.CEO) &&
                <Grid item xs={12} sx={{ mt: 0.5 }}>
                    <DeleteSupportPlan {...supportPlanData} />
                </Grid>}
        </Grid>
    </React.Fragment>
}

export default SupportPlanBase