import { Outlet } from 'react-router-dom';
import AuthWrapper from 'sections/auth/AuthWrapper';

// ==============================|| LAYOUT - BLANK PAGES ||============================== //

const PagesLayout = () => {
  return <AuthWrapper>
    <Outlet />
  </AuthWrapper>;
};

export default PagesLayout;
