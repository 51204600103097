import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// assets
import error500 from 'assets/images/maintenance/Error500.png';
import { setTitleAndDescription } from 'utils/seo-helper';
import { useEffect } from 'react';

import useAuth from 'hooks/useAuth';
import { PageUrl } from 'const/page-url';
import useApiStatusTransition from 'hooks/useApiStatusTransition';

// ==============================|| ERROR 500 - MAIN ||============================== //

function Error500() {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { initialize } = useApiStatusTransition();

  useEffect(() => {
    setTitleAndDescription("OHANA | 500", "")
  }, [])
  return (
    <>
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
        <Grid item xs={12}>
          <Box sx={{ width: { xs: 350, sm: 396 } }}>
            <img src={error500} alt="mantis" style={{ height: '100%', width: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Stack justifyContent="center" alignItems="center">
            <Typography align="center" variant={matchDownSM ? 'h2' : 'h1'}>
              Server Error
            </Typography>
            <Typography color="textSecondary" variant="body2" align="center" sx={{ mt: 1 }}>
              サーバーで問題が発生しているためページを表示できません <br />しばらく時間を置いてからやり直してください
            </Typography>
            <Button sx={{ mt: 1 }}
              variant="contained"
              onClick={async () => {
                try {
                  initialize()
                  await logout();
                  navigate(PageUrl.Login.urlWithSlash, { replace: true });
                } catch (err) {
                }
              }}>
              ログイン画面に戻る
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default Error500;
