import { Outlet } from 'react-router-dom';
import AuthWrapper from 'sections/auth/AuthWrapper';

// project import
import GuestGuard from 'utils/route-guard/GuestGuard';

// ==============================|| LAYOUT - AUTH ||============================== //

const UnAuthorizedLayout = () => (
  <GuestGuard>
    <AuthWrapper>
      <Outlet />
    </AuthWrapper>
  </GuestGuard>
);

export default UnAuthorizedLayout;
