import { useMemo } from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { PageableModel } from "types/paging";
import { AppendixSupportTimesData, SupportPlanData } from "types/support-plan";
import { generatePageableEndPoint } from "utils/api-utils";
import { deleteRequest, fetcher, postRequest, putRequest } from "utils/axios";

const supportPlan = 'api/support-plan'
const supportPlanList = 'api/support-plan-list'
const supportPlanListFilter = 'api/support-plan-list/filter'
const supportPlanAlertList = 'api/support-plan-list/alert-list'
const supportPlanAlertCount = 'api/support-plan-list/alert-list/count'
const supportPlanAppendix = 'api/support-plan-appendix'
const supportPlanChangeOwner = 'api/support-plan/change-owner'

// 個別支援計画取得API (単一)
export function useGetSupportPlan(id: number) {

    const { data, isLoading } = useSWR([supportPlan, { params: { id: id } }], fetcher<SupportPlanData>)

    const memoizedValue = useMemo(
        () => ({
            data: data,
            isLoading: isLoading
        }),
        [data, isLoading]
    );
    return memoizedValue;
}

// 個別支援計画一覧取得API
export function useGetSupportPlanList(pageIndex: number, pageSize: number, classIds?: number[], childFullName?: string) {
    const query = [
        ...(classIds?.map(id => ({ key: "classIds", value: id.toString() })) || []),
        ...(childFullName ? [{ key: "childFullName", value: childFullName }] : [])
    ];

    const { data, isLoading } = useSWR(
        generatePageableEndPoint(pageIndex, pageSize, supportPlanList, query.length > 0 ? query : undefined),
        fetcher<PageableModel<SupportPlanData>>
    );

    const memoizedValue = useMemo(
        () => ({
            data: data,
            isLoading: isLoading
        }),
        [data, isLoading]
    );
    return memoizedValue;
}

// 更新が必要な個別支援計画一覧取得API
export function useGetSupportPlanAlertList(
    pageIndex: number,
    pageSize: number,
    minDays: number,
    maxDays: number,
    classId?: number,
) {
    const query = [
        { key: "classId", value: classId?.toString() },
        { key: "minDays", value: minDays.toString() },  // startDaysを追加
        { key: "maxDays", value: maxDays.toString() }   // endDaysを追加
    ];
    const { data, isLoading } = useSWR(
        generatePageableEndPoint(pageIndex, pageSize, supportPlanAlertList, query),
        fetcher<PageableModel<SupportPlanData>>,
        {
            revalidateOnFocus: false, // フォーカス時の再フェッチを無効化
            revalidateOnReconnect: false, // 再接続時の再フェッチを無効化
            refreshInterval: 0, // 自動リフレッシュを無効化
            dedupingInterval: 10800000, // データの重複フェッチを3時間（10800000ミリ秒）に一回に制限
        }
    );

    const memoizedValue = useMemo(
        () => ({
            data: data,
            isLoading: isLoading
        }),
        [data, isLoading]
    );
    return memoizedValue;
}

// 更新が必要な個別支援計画の数を取得するAPI
export function useGetSupportPlanAlertCount(
    minDays: number,
    maxDays: number,
    classId?: number,
) {
    const params: { [key: string]: any } = {
        minDays: minDays,
        maxDays: maxDays,
    };

    if (classId !== undefined) {
        params.classId = classId;
    }

    const endpoint = [supportPlanAlertCount, { params }];

    const { data, isLoading } = useSWR<number>(endpoint, fetcher);

    return { data, isLoading };
}

// 個別支援計画一覧取得API
export function useGetSupportPlanListByChildId(childId: number) {
    const { data, isLoading } = useSWR([supportPlanListFilter, { params: { childId: childId } }], fetcher<SupportPlanData[]>)

    const memoizedValue = useMemo(
        () => ({
            supportPlanList: data,
            isLoading: isLoading
        }),
        [data, isLoading]
    );
    return memoizedValue;
}

// 個別支援計画作成API
export function useCreateSupportPlan() {
    const { trigger, isMutating } = useSWRMutation(generatePageableEndPoint(1, 1, supportPlanList), postRequest<SupportPlanData>);
    return { createSupportPlan: trigger, isCreating: isMutating };
}

// 個別支援計画を更新するAPI
export function useUpdateSupportPlan() {
    const { trigger, isMutating } = useSWRMutation(generatePageableEndPoint(1, 1, supportPlanList), putRequest<SupportPlanData>);
    return { updateSupportPlan: trigger, isUpdating: isMutating };
}


// 個別支援計画のownerを変更するAPI
export function useChangeSupportPlanOwner() {
    const { trigger, isMutating } = useSWRMutation(generatePageableEndPoint(1, 1, supportPlanChangeOwner), putRequest<SupportPlanData>);
    return { changeOwner: trigger, isChanging: isMutating };
}

// サービス提供時間（個別支援計画別表） 作成・更新API
export function useUpdateSupportPlanAppendix() {
    const { trigger, isMutating } = useSWRMutation(supportPlanAppendix, putRequest<AppendixSupportTimesData>);
    return { updateSupportPlanAppendix: trigger, updateSupportPlanAppendixMutating: isMutating };
}

// サービス提供時間（個別支援計画別表） 取得API
export function useGetSupportPlanAppendix(supportPlanId: number) {
    const { data, isLoading } = useSWR([supportPlanAppendix, { params: { supportPlanId: supportPlanId } }], fetcher<AppendixSupportTimesData>);
    const memoizedValue = useMemo(
        () => ({
            supportPlanAppendix: data,
            supportPlanAppendixLoading: isLoading,
        }),
        [data, isLoading]
    );
    return memoizedValue;
}

// 個別支援計画削除API
export function useDeleteSupportPlan() {
    const { trigger, isMutating } = useSWRMutation(supportPlanList, deleteRequest<void>);
    return { deleteSupportPlan: trigger, isDeletingSupportPlan: isMutating };
}