// MONとかを変数名として扱えるように、上とかのやり方と違う実装方法でやった
export enum DayOfWeek {
    MON = 1,
    TUE = 2,
    WED = 3,
    THU = 4,
    FRI = 5,
    SAT = 6,
    HOLIDAY = 7
}
export const DayOfWeekStr = {
    [DayOfWeek.MON]: '月',
    [DayOfWeek.TUE]: '火',
    [DayOfWeek.WED]: '水',
    [DayOfWeek.THU]: '木',
    [DayOfWeek.FRI]: '金',
    [DayOfWeek.SAT]: '土',
    [DayOfWeek.HOLIDAY]: '日・祝日',
} as const;

// 各曜日に対応するビットマスクを定義
export const DayOfWeekBitmask = {
    [DayOfWeek.MON]: 1 << 0, // 0000001
    [DayOfWeek.TUE]: 1 << 1, // 0000010
    [DayOfWeek.WED]: 1 << 2, // 0000100
    [DayOfWeek.THU]: 1 << 3, // 0001000
    [DayOfWeek.FRI]: 1 << 4, // 0010000
    [DayOfWeek.SAT]: 1 << 5, // 0100000
    [DayOfWeek.HOLIDAY]: 1 << 6 // 1000000
} as const;

// ビットマスクから選択された曜日の文字列を取得し、月から日までの順番でカンマ区切りの文字列に変換する関数
export const getSelectedDaysStrByBitmask = (bitmask: number | undefined): string => {
    if(bitmask == undefined) return ""
    // 曜日をソートするための順序配列
    const dayOrder: DayOfWeek[] = [
        DayOfWeek.MON,
        DayOfWeek.TUE,
        DayOfWeek.WED,
        DayOfWeek.THU,
        DayOfWeek.FRI,
        DayOfWeek.SAT,
        DayOfWeek.HOLIDAY
    ];

    // ビットマスクから選択された曜日を取得
    const selectedDays = dayOrder
        .filter(day => (bitmask & DayOfWeekBitmask[day]) !== 0) // ビットマスクがセットされている曜日をフィルタリング
        .map(day => DayOfWeekStr[day]); // 曜日の文字列に変換

    // カンマ区切りの文字列に変換
    return selectedDays.join(', ');
};

// DayOfWeekビットマスクの許可されたすべての組み合わせを定義
const validDayOfWeekBitmask = [
    0b0000001, // MON
    0b0000010, // TUE
    0b0000100, // WED
    0b0001000, // THU
    0b0010000, // FRI
    0b0100000, // SAT
    0b1000000, // HOLIDAY
    0b0111111, // MON-SAT
    0b1111111  // MON-HOLIDAY
  ];

  // ビットマスクが許可された組み合わせのいずれかであることを確認するバリデーション関数
  export const isValidBitmask = (value: number | undefined): boolean => {
    // ビットマスクが未定義もしくは0の場合は有効とみなす（選択されていない場合を許容）
    if (value === undefined || value === 0) return true;

    // ビットマスクがvalidDayOfWeekBitmaskに含まれるか確認
    return validDayOfWeekBitmask.some(validBitmask => (value & validBitmask) === value);
};