import { EditOutlined, InfoCircleFilled, AreaChartOutlined, DeleteOutlined } from "@ant-design/icons";
import { Alert, Button, Typography } from "@mui/material";
import { useGetAssessmentStatus } from "api/assessment";
import { useDeleteGoal, useGetGoals } from "api/support-plan-goal";
import Loader from "components/Loader";
import MainCard from "components/MainCard";
import { PageUrl } from "const/page-url";
import { SupportPlanStatus } from "const/support-plan";
import { useState } from "react";
import { useNavigate } from "react-router";
import TargetCheckModal from "./TargetCheckModal";
import React from "react";
import BasicModal from "components/modal/BasicModal";
import { SupportPlanHubProps } from "pages/support-plan/base";
import { SnackbarProps } from 'types/snackbar';
import { openSnackbar } from 'hooks/useGetSnackbar';
import { useUpdateSupportPlan } from "api/support-plan";
import usePreventDoubleClick from "hooks/usePreventDoubleClick";

function TargetSetting({ data, setData }: SupportPlanHubProps) {
    const { assessmentStatus, assessmentStatusLoading } = useGetAssessmentStatus(data.systemClassificationId, data.id);
    const { supportPlanGoals, supportPlanGoalsLoading } = useGetGoals(data.id);
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [goalDeleteModalOpen, setGoalDeleteModalOpen] = useState(false);
    const { deleteGoals, isDeletingGoals } = useDeleteGoal()
    const { updateSupportPlan, isUpdating } = useUpdateSupportPlan();
    const { handleClick, isExecuting } = usePreventDoubleClick(async () => {
        await deleteGoals(data.id)
        const newData = {
            ...data,
            supportPlanStatus: SupportPlanStatus.FINISHED_ASSESSMENT
        }
        await updateSupportPlan(newData)
        openSnackbar({
            open: true,
            message: "目標内容を削除しました",
            variant: 'alert',
            alert: {
                color: 'success'
            }
        } as SnackbarProps);
        setData(newData)
        // ダイアログを閉じる
        setGoalDeleteModalOpen(false);
    })

    if (assessmentStatus == undefined || supportPlanGoals == undefined) return <Loader />

    return <React.Fragment>
        {(assessmentStatusLoading || supportPlanGoalsLoading || supportPlanGoals == undefined || isDeletingGoals || isUpdating || isExecuting) && <Loader />}
        <Typography variant="h2">目標設定</Typography>
        <MainCard content={false} sx={{ mt: 1.5, p: 3 }}>
            {data.isCurrentSupportPlan && <Button
                disabled={
                    data.supportPlanStatus == SupportPlanStatus.AFTER_CREATION
                    || data.supportPlanStatus == SupportPlanStatus.UNDER_ASSESSMENT
                    || data.supportPlanStatus == SupportPlanStatus.FINISHED_CREATING_PLAN
                    || data.supportPlanStatus == SupportPlanStatus.FINISHED_MONITORING
                }
                variant="contained"
                size="large"
                sx={{ m: 1.5 }}
                startIcon={<EditOutlined />}
                onClick={() => {
                    navigate(PageUrl.SupportPlanGoal.urlWithSlash, { state: data });
                }}>
                {(data.supportPlanStatus >= SupportPlanStatus.UNDER_CREATING_PLAN ? "目標編集" : "目標新規作成")}
            </Button>}
            {data.supportPlanStatus >= SupportPlanStatus.UNDER_CREATING_PLAN && <Button
                variant="contained"
                size="large"
                sx={{ m: 1.5 }}
                startIcon={<AreaChartOutlined />}
                onClick={() => {
                    setModalOpen(true)
                }}>
                設定内容確認
            </Button>}
            {data.supportPlanStatus == SupportPlanStatus.UNDER_CREATING_PLAN && <Button
                variant="contained"
                size="large"
                color="error"
                sx={{ m: 1.5 }}
                startIcon={<DeleteOutlined />}
                onClick={() => {
                    setGoalDeleteModalOpen(true)
                }}>
                目標内容クリア
            </Button>}
        </MainCard>
        {(data.supportPlanStatus == SupportPlanStatus.FINISHED_ASSESSMENT || data.supportPlanStatus == SupportPlanStatus.UNDER_CREATING_PLAN)
            && <Alert sx={{ mt: 2 }} color="info" icon={<InfoCircleFilled />}>
                目標を二つ以上設定すると個別支援計画を提出できるようになります
            </Alert>
        }
        <TargetCheckModal open={modalOpen} modalToggler={setModalOpen} supportPlanData={data} />
        <BasicModal
            open={goalDeleteModalOpen}
            setOpen={setGoalDeleteModalOpen}
            title={`目標内容を削除します`}
            description={`この操作は取り消せません。実行してもよろしいですか?`}
            submitButtonString='続行する'
            onCLickPositiveButton={handleClick} />
    </React.Fragment>
}

export default TargetSetting;