export const CommonString = {
    UserAuthority: "Authority",
    UserFamilyNameKanji: "UserFamilyNameKanji",
    UserFirstNameKanji: "UserFirstNameKanji",
    UserFamilyNameHiragana: "UserFamilyNameHiragana",
    UserFirstNameHiragana: "UserFirstNameHiragana",
    UserEmail: "UserEmail",
    ClassId: "ClassId",
    JihatsukanType: "JihatsukanType",
    Id: "id",
  } as const;