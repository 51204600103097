import { CalculatorOutlined } from "@ant-design/icons";
import { Button, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { PageUrl } from "const/page-url";
import { SupportPlanStatus } from "const/support-plan";
import { SUPPORT_PLAN_HUB_SESSION_STRING } from "pages/support-plan/base";
import { SupportPlanData } from "types/support-plan";

function SupportPlan(supportPlanData: SupportPlanData) {

    return <>
        <Typography variant="h2">個別支援計画書</Typography>
        <MainCard content={false} sx={{ mt: 1.5, p: 3 }}>
            <Button
            variant="contained"
            disabled={supportPlanData.supportPlanStatus < SupportPlanStatus.FINISHED_CREATING_PLAN}
            size="medium"
            sx={{ m: 1.5 }}
            startIcon={<CalculatorOutlined />}
            onClick={() =>{
                localStorage.setItem(SUPPORT_PLAN_HUB_SESSION_STRING, JSON.stringify(supportPlanData));
                window.open(PageUrl.SupportPlanPdf.urlWithSlash, '_blank', 'rel=noopener noreferrer')
            }}>
                個別支援計画書出力
            </Button>
        </MainCard>
    </>
}

export default SupportPlan;