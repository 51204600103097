import { useGetIsMaintenance } from 'api/admin/maintenance';
import Loader from 'components/Loader';
import useApiStatusTransition from 'hooks/useApiStatusTransition';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// ==============================|| LAYOUT - メンテナンス ||============================== //
// メンテナンス中かを確認する必要がある画面の親レイアウトとして使用する
// 他のAPIを画面表示直後に叩く画面ではそのAPI実行時にメンテナンス有無も併せて確認できるのでこのレイアウトは必要ない
// 一方、画面表示直後にAPIを叩かないような画面ではメンテナンス中かどうかを知る術がないのでこのレイアウトを親レイアウトにする
const MaintenanceGuard = () => {

    const { isMaintenance, isMaintenanceLoading } = useGetIsMaintenance()
    const { executeMaintenanceTransition } = useApiStatusTransition();

    useEffect(() => {
        if (isMaintenance) {
            executeMaintenanceTransition()
        }
    }, [isMaintenance])

    if (isMaintenanceLoading) return <Loader />

    return <Outlet />;
};

export default MaintenanceGuard;
