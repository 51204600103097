import { CommonString } from 'const/common-string';
import { ReactElement, useEffect } from 'react';

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //
// ==============================|| ページ読み込み直後はページのトップから表示させるための設定 ||============================== //

const ScrollTop = ({ children }: { children: ReactElement | null }) => {
  useEffect(() => {
    setTimeout(function () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 10);
    localStorage.removeItem("TempAccessToken");
  }, []);

  return children || null;
};

export default ScrollTop;
