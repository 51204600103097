import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';

// types
import { FirebaseReAuthModal } from 'types/fb-re-auth-modal';

export const endpoints = {
  key: 'fb-re-auth-modal'
};

const initialState: FirebaseReAuthModal = {
  onReAuthCompleted: () => { },
  open: false,
}

export function useFirebaseReAuthModal() {
  const { data } = useSWR(endpoints.key, () => initialState, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
  const memoizedValue = useMemo(() => ({ firebaseReAuthModalData: data! }), [data]);
  return memoizedValue;
}

export function openFirebaseReAuthModal(onReAuthCompleted: () => void) {
  mutate(
    endpoints.key,
    (currentFirebaseReAuthModal: any) => {
      return {
        ...currentFirebaseReAuthModal,
        onReAuthCompleted: onReAuthCompleted || initialState.onReAuthCompleted,
        open: true,
      };
    },
    false
  );
}

export function closeFirebaseReAuthModal() {
  mutate(
    endpoints.key,
    (currentFirebaseReAuthModal: any) => {
      return { ...currentFirebaseReAuthModal, open: false };
    },
    false
  );
}