// material-ui
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, TextField } from '@mui/material';
import { closeFirebaseReAuthModal, useFirebaseReAuthModal } from 'hooks/useFbReAuthModal';
import Loader from 'components/Loader';
import useAuth from 'hooks/useAuth';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { authValidation } from 'validation/auth';
import { useState } from 'react';
import useScriptRef from 'hooks/useScriptRef';
import CustomFirebaseError from 'api/error/ohana-error';
import { AxiosError } from 'axios';

// ==============================|| DIALOG - FORM ||============================== //

const FirebaseReAuthModal = () => {
  const scriptedRef = useScriptRef();
  const [isLoading, setLoading] = useState(false);
  const { firebaseReAuthModalData } = useFirebaseReAuthModal();
  const { reAuthenticate } = useAuth();
  const handleClose = () => {
    closeFirebaseReAuthModal()
  };

  if (firebaseReAuthModalData === undefined) return <Loader />;

  return (
    <>
      {isLoading && <Loader />}
      <Dialog open={firebaseReAuthModalData.open} onClose={handleClose}>
        <Box sx={{ p: 1, py: 1.5 }}>
          <Formik
            initialValues={{
              password: '',
              submit: null
            }}
            validationSchema={Yup.object().shape({
              password: authValidation.password
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              setLoading(true);
              try {
                await reAuthenticate(values.password)
                await firebaseReAuthModalData.onReAuthCompleted()
                if (scriptedRef.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }
                closeFirebaseReAuthModal()
              } catch (err: unknown) {
                if (err instanceof CustomFirebaseError || err instanceof AxiosError) {
                  if (scriptedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }
              }
              setLoading(false);
            }}>
            {({ errors, handleSubmit, touched, isSubmitting, handleBlur, handleChange, values }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogTitle>パスワードを入力してください</DialogTitle>
                <DialogContent>
                  <DialogContentText sx={{ mb: 2 }}>
                    セキュリティ上重要な操作を行うには、パスワードの入力が必要です
                  </DialogContentText>
                  <TextField
                    id="password"
                    placeholder="MyPassword123"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange} />
                  {touched.password && errors.password && (
                    <FormHelperText error id="helper-text-password">
                      {errors.password}
                    </FormHelperText>
                  )}
                  {errors.submit && (
                    <FormHelperText
                      error
                      sx={{ whiteSpace: "pre-wrap" }}>{errors.submit}</FormHelperText>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button color="error" onClick={handleClose}>
                    キャンセル
                  </Button>
                  <Button variant="contained" type="submit" disableElevation disabled={isSubmitting} >
                    確定
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Box>
      </Dialog >
    </>
  );
}

export default FirebaseReAuthModal;