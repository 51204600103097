import { ReactElement } from "react";
import FirebaseReAuthModal from "./modal/FirebaseReAuthModal";

const FirebaseReAuthModalProvider = ({ children }: { children: ReactElement }) => {

    return <>
        <FirebaseReAuthModal />
        {children}
    </>;
};

export default FirebaseReAuthModalProvider;