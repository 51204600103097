import { CalculatorOutlined, EditOutlined, InfoCircleFilled, CheckOutlined } from "@ant-design/icons";
import { Alert, Box, Button, Typography } from "@mui/material";
import { useUpdateSupportPlan } from "api/support-plan";
import { useGetSupportPlanSubmittable } from "api/support-plan-goal";
import Loader from "components/Loader";
import MainCard from "components/MainCard";
import { PageUrl } from "const/page-url";
import { SupportPlanStatus } from "const/support-plan";
import { SnackbarProps } from 'types/snackbar';
import { openSnackbar } from 'hooks/useGetSnackbar';
import { SUPPORT_PLAN_HUB_SESSION_STRING, SupportPlanHubProps } from "pages/support-plan/base";
import { useEffect, useState } from "react";
import { useDeleteMonitoring } from "api/monitoring";
import BasicModal from "components/modal/BasicModal";
import React from "react";
import usePreventDoubleClick from "hooks/usePreventDoubleClick";
import ja from 'date-fns/locale/ja'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { convertToLocaleDateString } from "const/date-formatter";

function PreSupportPlan({ data, setData }: SupportPlanHubProps) {

    const { updateSupportPlan, isUpdating } = useUpdateSupportPlan();
    const [modalOpen, setModalOpen] = useState(false);
    const [createdDate, setCreatedDate] = useState(data.supportCreateDate ? new Date(data.supportCreateDate) : new Date());
    const { isLoading, isSubmittable, mutate } = useGetSupportPlanSubmittable(data.id)
    const [currentStatus, setCurrentStatus] = useState(data.supportPlanStatus)
    const { deleteMonitoring } = useDeleteMonitoring()
    const { handleClick: handleSubmitClick } = usePreventDoubleClick(async () => {
        const newData = {
            ...data,
            supportCreateDate: createdDate.toLocaleDateString(),
            supportPlanStatus: SupportPlanStatus.FINISHED_CREATING_PLAN,
        }
        await updateSupportPlan(newData)
        openSnackbar({
            open: true,
            message: "個別支援計画書(案)を提出しました",
            variant: 'alert',
            alert: {
                color: 'success'
            }
        } as SnackbarProps);
        setData(newData)
        mutate()
    })
    const { handleClick: handleEditClick } = usePreventDoubleClick(async () => {
        const newData = {
            ...data,
            supportPlanStatus: SupportPlanStatus.UNDER_CREATING_PLAN
        }
        await updateSupportPlan(newData)
        await deleteMonitoring(data.id)
        openSnackbar({
            open: true,
            message: "ステータスを計画書作成に戻しました",
            variant: 'alert',
            alert: {
                color: 'success'
            }
        } as SnackbarProps);
        setData(newData)
        mutate()
        // ダイアログを閉じる
        setModalOpen(false);
    })
    useEffect(() => {
        // 個別支援計画のステータスが変わる度に提出可能かどうかを調べる
        if(currentStatus != data.supportPlanStatus){
            mutate()
        }
        setCurrentStatus(data.supportPlanStatus)
    }, [data])
    if (isLoading) return <Loader />

    return <React.Fragment>
        {(isLoading || isUpdating) && <Loader />}
        <Typography variant="h2">個別支援計画書（案）</Typography>
        <MainCard content={false} sx={{ mt: 1.5, p: 3 }}>
            {isSubmittable == false && <Button
                variant="contained"
                disabled={data.supportPlanStatus < SupportPlanStatus.FINISHED_CREATING_PLAN}
                size="medium"
                sx={{ m: 1.5 }}
                startIcon={<CalculatorOutlined />}
                onClick={() => {
                    localStorage.setItem(SUPPORT_PLAN_HUB_SESSION_STRING, JSON.stringify(data));
                    window.open(PageUrl.PreSupportPlanPdf.urlWithSlash, '_blank', 'rel=noopener noreferrer')
                }}>
                個別支援計画書(案)出力
            </Button>}
            {(isSubmittable == true && data.isCurrentSupportPlan) && <Box
                display="flex"
                justifyContent="left"
                alignItems="center"
            > <Button
                variant="contained"
                size="medium"
                color="error"
                sx={{ m: 1.5 }}
                startIcon={<CheckOutlined />}
                onClick={handleSubmitClick}>
                    個別支援計画書(案)提出
                </Button>
                <LocalizationProvider
                    adapterLocale={ja}
                    dateAdapter={AdapterDateFns}
                    dateFormats={{ monthAndYear: "yyyy年MM月", monthShort: "M月", year: "yyyy年" }}
                >
                    <DatePicker
                        views={['year', 'month', 'day',]}
                        sx={{ m: 1.5 }}
                        format="yyyy年M月dd日"
                        onChange={(newValue) => {
                            if (newValue && newValue instanceof Date) {
                                setCreatedDate(newValue)
                            }
                        }}
                    />
                </LocalizationProvider>
                <Typography variant="subtitle1" sx={{ m: 1.5 }}>
                    提出日 : {convertToLocaleDateString(createdDate)}
                </Typography>
            </Box>
            }
            {(data.supportPlanStatus >= SupportPlanStatus.FINISHED_CREATING_PLAN && data.isCurrentSupportPlan) &&
                <Button
                    variant="contained"
                    size="medium"
                    sx={{ m: 1.5 }}
                    color="error"
                    startIcon={<EditOutlined />}
                    onClick={async () => {
                        setModalOpen(true);
                    }}
                >
                    個別支援計画書(案)修正
                </Button>
            }
        </MainCard>
        {(data.supportPlanStatus >= SupportPlanStatus.FINISHED_CREATING_PLAN && data.isCurrentSupportPlan)
            && <Alert sx={{ mt: 2 }} color="warning" icon={<InfoCircleFilled />}>
                「個別支援計画書(案)修正」ボタンを押すと提出後に作成されたモニタリングは削除されますのでご注意ください
            </Alert>
        }
        {(isSubmittable == true && data.isCurrentSupportPlan) && <Alert sx={{ mt: 2 }} color="info" icon={<InfoCircleFilled />}>
            個別支援計画の提出日はピッカーから指定可能です
        </Alert>
        }
        <BasicModal
            open={modalOpen}
            setOpen={setModalOpen}
            title={`個別支援計画書(案)を修正します`}
            description={`この操作は取り消せません。提出後に作成されたモニタリングは削除されますがよろしいですか?`}
            submitButtonString='続行する'
            onCLickPositiveButton={handleEditClick} />
    </React.Fragment>
}

export default PreSupportPlan;