// assets
import {
  HomeOutlined,
  AppstoreAddOutlined,
  SmileOutlined,
  AuditOutlined
} from '@ant-design/icons';
import { PageUrl } from 'const/page-url';

// type
import {  NavItemType } from 'types/menu';

// icons
const icons = {
  HomeOutlined,
  AppstoreAddOutlined,
  SmileOutlined,
  AuditOutlined
};

// ==============================|| MENU ITEMS - 授業関連 ||============================== //

const menuMain: NavItemType = {
  id: 'group-main',
  title:  <div>作成</div>,
  icon: icons.AppstoreAddOutlined,
  needDeveloperAuthority: false,
  type: 'group',
  children: [
    {
      id: 'dashBoard',
      title: <div>ダッシュボード</div>,
      type: 'item',
      url: PageUrl.DashBoard.urlWithSlash,
      icon: icons.HomeOutlined,
      breadcrumbs: false
    },
    {
      id: 'children',
      title: <div>利用者</div>,
      type: 'item',
      url: PageUrl.Children.urlWithSlash,
      icon: icons.SmileOutlined,
      breadcrumbs: false
    },
    {
      id: 'plan',
      title: <div>個別支援計画書</div>,
      type: 'item',
      url: PageUrl.SupportPlanList.urlWithSlash,
      icon: icons.AuditOutlined,
      breadcrumbs: false,
    }
  ]
};

export default menuMain;
