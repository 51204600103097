// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Typography } from '@mui/material';

// project import
import MainCard from 'components/MainCard';

// assets
import BackLeft from 'assets/images/profile/UserProfileBackLeft';
import BackRight from 'assets/images/profile/UserProfileBackRight';

// types
import { ThemeDirection } from 'types/config';

// ==============================|| ページの一番上に表示するカード (ページの説明を記述) ||============================== //

export interface PageTopIconCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const PageTopIconCard = (prop: PageTopIconCardProps) => {
  const theme = useTheme();

  return (
    <MainCard border={false} content={false} sx={{ bgcolor: 'primary.lighter', position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          bottom: '-7px',
          left: 0,
          zIndex: 1,
          ...(theme.direction === ThemeDirection.RTL && {
            transform: 'rotate(180deg)',
            top: -7,
            bottom: 'unset'
          })
        }}
      >
        <BackLeft />
      </Box>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ position: 'relative', zIndex: 5 }}>
        <Grid item>
          <Stack direction="row" spacing={4} alignItems="center">
          <Box sx={{ p: 3, verticalAlign: "center" }}>
              {prop.icon}
            </Box>
            <Stack spacing={0.75}>
              <Typography variant="h5">{prop.title}</Typography>
              <Typography variant="body2" color="secondary">
                {prop.description}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 1,
          ...(theme.direction === ThemeDirection.RTL && {
            transform: 'rotate(180deg)',
            top: -10,
            bottom: 'unset'
          })
        }}
      >
        <BackRight />
      </Box>
    </MainCard>
  );
};

export default PageTopIconCard;
