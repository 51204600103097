import { useMemo } from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { fetcher, postRequest } from "utils/axios";

const endpoint = 'api/admin/maintenance'

// メンテナス中かどうかを取得するAPI
export function useGetIsMaintenance(shouldFetch: boolean = true) {
    const { data, isLoading } = useSWR(shouldFetch ? endpoint : null, fetcher<boolean>);
    const memoizedValue = useMemo(
        () => ({
            isMaintenance: data,
            isMaintenanceLoading: isLoading
        }),
        [data, isLoading]
    );

    return memoizedValue;
}

// メンテナス状態を更新する
export function useUpdateIsMaintenance() {
    const { trigger, isMutating } = useSWRMutation(endpoint, postRequest<boolean>);
    return { updateMaintenanceFlag: trigger, isMaintenanceFlagUpdating: isMutating };
}