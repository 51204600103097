// material-ui
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { ThemeMode } from 'types/config';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const theme = useTheme();
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="Mantis" width="100" />
     *
     */
    <React.Fragment>
      <svg width="150" height="35" xmlns="http://www.w3.org/2000/svg">
        <g fill="black">
          <path
            d="M10,20 L14,10 L18,20 L22,10 L26,20 L22,30 L18,20 L14,30 Z"
            fill={theme.palette.primary.main} />
          <text x="35" y="27" font-family="Arial" font-size="22" fill="black">OHANA</text>
        </g>
      </svg>
    </React.Fragment>
  );
};

export default LogoMain;
