
import { isValidBitmask } from 'const/day-of-week';
import { SchoolCategory, ServiceCategory, TimeDIvision } from 'types/child';
import * as Yup from 'yup';

const passwordRegex = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,20}$/
export const passwordRegexError = 'パスワードは8文字以上でアルファベットの大文字・小文字・数字それぞれを最低1文字ずつ含む必要があります'

export const authValidation = {
    name: Yup.string().max(20, 'ユーザー名は20文字以内で入力してください').required('ユーザー名は必須項目です'),
    familyNameKanji: Yup.string().max(10, '漢字性は10文字以内で入力してください').required('漢字性は必須項目です'),
    firstNameKanji: Yup.string().max(10, '漢字名は10文字以内で入力してください').required('漢字名は必須項目です'),
    firstNameHiragana: Yup.string().max(10, 'かな性は10文字以内で入力してください').required('かな性は必須項目です'),
    familyNameHiragana: Yup.string().max(10, 'かな名は10文字以内で入力してください').required('かな名は必須項目です'),
    className: Yup.string().max(20, '事業所名は20文字以内で入力してください').required('事業所名は必須項目です'),
    classNameHiragana: Yup.string().max(20, '事業所かな名は20文字以内で入力してください').required('事業所かな名は必須項目です'),
    classNumber: Yup.number().typeError("事業所番号は数字で入力してください").required('事業所番号は必須項目です'),
    jihatsukanType: Yup.mixed().required('児発管の選択は必須です'),
    authority: Yup.mixed().required('権限は必須項目です'),
    class: Yup.number().required('教室は必須項目です'),
    email: Yup.string().email('メールアドレスを正しく入力してください').max(255).required('メールアドレスは必須項目です'),
    password: Yup.string().max(255).min(8,"パスワードは8文字以上で設定してください").required('パスワードは必須項目です').matches(passwordRegex, passwordRegexError),
    // パスワード再入力用バリデーション
    passwordConfirm: Yup.string().max(255).required('確認用パスワードは必須項目です').matches(passwordRegex, passwordRegexError).test('confirmPassword', 'パスワードが一致していません', (confirmPassword, yup) => yup.parent.password === confirmPassword),
    // 受給者番号
    recipientId: Yup.number().typeError("数字で入力してください").required('受給者番号は必須項目です'),
    birthDate: Yup.string().required('生年月日は必須項目です'),

    // 以下個別支援関係のバリデーション
    supportStartDate: Yup.string().required('支援開始日は必須項目です'),
    supportUpdateDate: Yup.string().required('支援更新日は必須項目です'),
    serviceCategory: Yup.mixed().oneOf([ServiceCategory.AFTER_SCHOOL_DAY_SERVICE, ServiceCategory.CHILD_DEVELOPMENT_SUPPORT, ServiceCategory.SCHOOL], '正しい利用サービスを選択してください').required('利用サービスは必須項目です'),
    childSelection: Yup.mixed().required('利用者を選択してください'),
    staffSelection: Yup.mixed().required('スタッフを選択してください'),
    supportPlanOwner: Yup.mixed().required('児童発達支援管理責任者選択してください'),
    schoolCategory: Yup.mixed().oneOf([SchoolCategory.PRESCHOOLER, SchoolCategory.NORMAL_SCHOOL, SchoolCategory.NORMAL_JUNIOR_HIGH_SCHOOL, SchoolCategory.SPECIAL_CARE_SCHOOL, SchoolCategory.SPECIAL_CARE_JUNIOR_HIGH_SCHOOL], '正しい通学区分を選択してください').required('通学区分を選択してください'),
    guardianNeeds: Yup.string().max(150, '生活に対する困り事・ニーズ(保護者)は150文字以内で入力してください'),
    childNeeds: Yup.string().max(150, '生活に対する困り事・ニーズ(児童)は150文字以内で入力してください'),
    generalSupportDirection: Yup.string().max(200, '総合的な支援の方針は200文字以内で入力してください'),
    supportPlanInternalMeetingDate: Yup.date(),
    supportPlanExternalMeetingDate: Yup.date(),
    supportPlanNote: Yup.string().max(1000, '目標の設定理由は1000文字以内で入力してください'),
    timeDivision: Yup.mixed().oneOf([TimeDIvision.ONE, TimeDIvision.TWO, TimeDIvision.THREE], '正しい区分を選択してください'),
    supportWeekDay: Yup.number().test('is-valid-bitmask', '正しい曜日を選択してください', isValidBitmask),
    consultationSupportFacility: Yup.string().max(250, '相談支援事業所は1000文字以内で入力してください'),
    consultationSupporter: Yup.string().max(20, '相談支援専門員は20文字以内で入力してください'),
    reasonForGoal: Yup.string().max(1000, '目標の設定理由は1000文字以内で入力してください'),
    monitoringCreateDate: Yup.string().required('モニタリング作成日は必須項目です'),
    evaluation: Yup.mixed().oneOf([0, 1, 2], "達成評価の入力値が不正です").required('達成評価は必須項目です'),
    evaluationDetail: Yup.string().required('評価内容は必須項目です').max(50, '評価内容は50文字以内で入力してください'),
    needReview: Yup.mixed().required("見直し有無は必須項目です"),
    monitoringNote: Yup.string().max(50, 'その他留意事項は50文字以内で入力してください'),

    // 汎用的なバリデーション
    generalString: Yup.string().required('必須項目です'),
    generalNumber: Yup.number().typeError("数時で入力してください").required('必須項目です'),
}