import { CommonString } from "const/common-string";
import { useMemo } from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { UserProfile } from "types/auth";
import { PageableModel } from "types/paging";
import { generatePageableEndPoint } from "utils/api-utils";
import { deleteRequest, fetcher, postRequest, putRequest } from "utils/axios";

const endpointKey = 'api/staff'
const endpointKeyForMyProfile = 'api/account/profile'
const endpointQuitService = "/api/account/quit"
const endpointRegister = "api/account/register"
const endpointAccountProfile = "api/account/update-profile"
const updateEmail = "api/account/update-email"

export enum UserFilter {
    JIHATSUKAN_ONLY = "JIHATSUKAN_ONLY",        // 自発管のみ
    JIHATSUKAN_EXCEPT_SELF = "JIHATSUKAN_EXCEPT_SELF", // 自分を除く自発管を全て取得
    EXCLUDE_SELF = "EXCLUDE_SELF",              // 自分以外
    INCLUDE_SELF = "INCLUDE_SELF"               // 全ユーザー（自分も含む）
}


// スタッフ一覧取得API
export function useGetStaff(
    pageIndex: number,
    pageSize: number,
    userFilter: UserFilter = UserFilter.EXCLUDE_SELF,
    classIds?: number[], // 配列のままで受け取る
) {
    const query = [
        { key: "userFilter", value: userFilter },
        { key: "classIds", value: classIds }
    ]; // Enumを使ってフィルタを設定
    const { data, isLoading, mutate } = useSWR(
        generatePageableEndPoint(pageIndex, pageSize, endpointKey, query),
        fetcher<PageableModel<UserProfile>>
    );

    const memoizedValue = useMemo(
        () => ({
            staff: data as PageableModel<UserProfile>,
            isStaffLoading: isLoading,
            mutate: mutate
        }),
        [data, isLoading, mutate]
    );

    return memoizedValue;
}

// 自分のプロフィール情報を取得する
export function useGetMyProfile() {
    const { data, isLoading } = useSWR(endpointKeyForMyProfile, fetcher<UserProfile>)
    const memoizedValue = useMemo(
        () => ({
            userInfo: data as UserProfile,
            isLoading: isLoading
        }),
        [data, isLoading]
    );
    return memoizedValue
}

// スタッフ登録API
export function useUpdateMyEmail() {
    const { trigger, isMutating } = useSWRMutation(updateEmail, postRequest<string>);
    return { updateEmail: trigger, isEMailUpdating: isMutating };
}

// スタッフ登録API
export function useRegisterAccount() {
    const { trigger, isMutating } = useSWRMutation(endpointRegister, postRequest<void>);
    return { register: trigger, isRegistering: isMutating };
}

// スタッフの情報編集API
export function useUpdateStaff(
    pageIndex: number = 0,
    pageSize: number = 10,
    userFilter: UserFilter = UserFilter.EXCLUDE_SELF
) {
    const query = [{ key: "userFilter", value: userFilter }]; // Enumを使ってフィルタを設定
    const { trigger, isMutating } = useSWRMutation(generatePageableEndPoint(pageIndex, pageSize, endpointKey, query), putRequest<void>);
    return { trigger, isMutating };
}

// スタッフの情報編集API
export function useUpdateMyProfile() {
    const { trigger, isMutating } = useSWRMutation(endpointAccountProfile, putRequest<void>);
    return { updateMyProfile: trigger, isUpdating: isMutating };
}

// スタッフ削除API
export function useQuitService(
    userFilter: UserFilter = UserFilter.EXCLUDE_SELF
) {
    const query = [{ key: "userFilter", value: userFilter }]; // Enumを使ってフィルタを設定
    const { trigger, isMutating } = useSWRMutation(generatePageableEndPoint(0, 100, endpointQuitService, query), deleteRequest<void>);
    return { quitService: trigger, isQuitting: isMutating };
}