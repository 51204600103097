import { AxiosError } from 'axios';
import { SWRConfig } from 'swr';
import useApiStatusTransition from 'hooks/useApiStatusTransition';
import { ServerErrorStatusType } from 'api/error/ohana-error';

type Props = {
    children: React.ReactNode;
};

export const SWRSetting = (props: Props) => {
    const { children } = props;
    const { executeInternalServerErrorTransition, executeUnAuthorizeTransition, executeMaintenanceTransition } = useApiStatusTransition();

    return (
        <SWRConfig
            value={
                {
                    // 最大リトライ回数を3回に設定
                    errorRetryCount: 3,
                    // リトライ間隔を5秒に設定
                    errorRetryInterval: 5000,
                    // フォーカス時の再フェッチを無効化
                    revalidateOnFocus: false,
                    // 再接続時の再フェッチを無効化
                    revalidateOnReconnect: false,
                    onError(err) {
                        if (err instanceof AxiosError) {
                            if (err.response?.status == 500 || err.response?.status == 400) {
                                executeInternalServerErrorTransition()
                            }
                            if (err.response?.status == 503) {
                                executeMaintenanceTransition()
                            }
                            if (err.response?.status == 403) {
                                // 403でも退会処理時のエラーの場合は退会画面で処理を実装する
                                if (err.response.data["type"] == ServerErrorStatusType.LastUserDeletionError
                                    || err.response.data["type"] == ServerErrorStatusType.ErrorNeedCreateJihatsukan
                                    || err.response.data["type"] == ServerErrorStatusType.ErrorChangeJihatsukan
                                    || err.response.data["type"] == ServerErrorStatusType.ErrorNeedChangeMonitoringOwner) {
                                    return
                                }
                                // 上記以外の403は全てログイン画面に遷移させる
                                executeUnAuthorizeTransition()
                            }
                        }
                    },

                }}
        >
            {children}
        </SWRConfig>
    );
};