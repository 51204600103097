import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
// import { APP_DEFAULT_PATH } from 'config';
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { PageUrl } from 'const/page-url';

// ==============================|| GUEST GUARD ||============================== //
//  ログイン済みユーザーがログイン非必須の画面に遷移した際にダッシュボードに遷移させるための処理  //
const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(location?.state?.from ? location?.state?.from : PageUrl.DashBoard.url, {
        state: {
          from: ''
        },
        replace: true
      });
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default GuestGuard;