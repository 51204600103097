import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import Error404 from 'pages/maintenance/404';
import Error500 from 'pages/maintenance/500';

// ==============================|| ELEMENT ERROR - COMMON ||============================== //

const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 />
    }
  }
  return <Error500 />
};

export default ErrorBoundary;
