export type Child = {
    id: number;
    childFamilyNameKanji: string;
    childFirstNameKanji: string;
    childFamilyNameHiragana: string;
    childFirstNameHiragana: string;
    childBirthDate: string;
    childServiceCategory: number;
    childSchoolCategory: number;
    recipientId: string;
    classId: number;
}

export enum ServiceCategory {
    CHILD_DEVELOPMENT_SUPPORT = '児童発達支援',
    AFTER_SCHOOL_DAY_SERVICE = '放課後デイサービス',
    SCHOOL = '学校'
}

export enum SchoolCategory {
    PRESCHOOLER = '未就学児童',
    NORMAL_SCHOOL = ' 地域小学校',
    NORMAL_JUNIOR_HIGH_SCHOOL = '地域中・高学校',
    SPECIAL_CARE_SCHOOL = '特別支援小学校',
    SPECIAL_CARE_JUNIOR_HIGH_SCHOOL = '特別支援中・高学校',
}

export enum TimeDIvision {
    // 区分1 : 30分以上1時間30分以下
    ONE = '区分1',
    // 区分2 : 1時間30分以上3時間以下
    TWO = ' 区分2',
    // 区分3 : 3時間以上5時間以下
    THREE = '区分3',
}