import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { PageUrl } from 'const/page-url';

// ==============================|| AUTH GUARD ||============================== //
// 開発者以外を弾くための設定
const DeveloperGuard = ({ children }: GuardProps) => {
    const { isLoggedIn, isDeveloper } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate(PageUrl.Login.url, {
                state: {
                    from: location.pathname
                },
                replace: true
            });
            return
        }

        if (!isDeveloper) {
            navigate(PageUrl.DashBoard.url, {
                state: {
                    from: location.pathname
                },
                replace: true
            });
            return
        }
    }, [isLoggedIn, navigate, location]);

    return children;
};

export default DeveloperGuard;
