import { AxiosError } from "axios";
import { FirebaseError } from "firebase/app";
import { when } from "utils/when";


/**
 * Firebaseのエラークラス
*/
class CustomFirebaseError extends Error {

    statusCode: number = 500;

    constructor(error: unknown) {
        super();
        this.handleError(error);
    }

    private handleError(error: unknown) {
        if (error instanceof FirebaseError) {
            // FBから返却されるエラーコードを独自のエラーコードに変換する
            // FBから返却されるエラーコードは英語なので自前のエラーに変換して扱いやすくするため
            const errorStatusCode = when(error.code)
                .on(
                    (value) =>
                        value == FirebaseErrorString.InvalidEmail ||
                        value == FirebaseErrorString.InvalidPassword ||
                        value == FirebaseErrorString.UserNotFound ||
                        value == FirebaseErrorString.InvalidActionCode ||
                        value == FirebaseErrorString.InvalidCredential,
                    () => 400
                )
                .on(
                    (value) =>
                        value == FirebaseErrorString.InvalidUserToken ||
                        value == FirebaseErrorString.RequiresRecentLogin ||
                        value == FirebaseErrorString.UserTokenExpired ||
                        value == FirebaseErrorString.UserSignedOut,
                    () => 401
                )
                .on(
                    (value) =>
                        value == FirebaseErrorString.Timeout,
                    () => 408
                )
                .on(
                    (value) =>
                        value == FirebaseErrorString.UserAlreadyExist ||
                        value == FirebaseErrorString.EmailAlreadyExists,
                    () => 409
                )
                .on(
                    (value) => value == FirebaseErrorString.TooManyRequest,
                    () => 429
                )
                .otherwise(() => 500);

            const errorMessage = when(error.code)
                .on(
                    (value) => value === FirebaseErrorString.InvalidActionCode,
                    () => ErrorString.InvalidActionCodeTitle
                )
                .on(
                    (value) => value === FirebaseErrorString.UserNotFound,
                    () => ErrorString.UserNotFoundTitle
                )
                .on((value) =>
                    value == FirebaseErrorString.InvalidEmail,
                    () => ErrorString.InvalidEmail
                )
                .on((value) =>
                    value == FirebaseErrorString.InvalidPassword,
                    () => ErrorString.InvalidPassword
                )
                .on((value) =>
                    value == FirebaseErrorString.InvalidCredential,
                    () => ErrorString.InvalidCredential
                )
                .on(
                    (value) =>
                        value == FirebaseErrorString.InvalidUserToken ||
                        value == FirebaseErrorString.RequiresRecentLogin ||
                        value == FirebaseErrorString.UserTokenExpired ||
                        value == FirebaseErrorString.UserSignedOut,
                    () => ErrorString.UnAuthorized
                )
                .on(
                    (value) =>
                        value == FirebaseErrorString.Timeout,
                    () => ErrorString.Timeout
                )
                .on(
                    (value) =>
                        value == FirebaseErrorString.NetworkRequestFailed,
                    () => ErrorString.NetworkNotConnected
                )
                .on(
                    (value) =>
                        value == FirebaseErrorString.UserAlreadyExist ||
                        value == FirebaseErrorString.EmailAlreadyExists,
                    () => ErrorString.EmailAlreadyExistMessage
                )
                .on(
                    (value) => value == FirebaseErrorString.TooManyRequest,
                    () => ErrorString.TooManyRequests
                )
                .otherwise(() => ErrorString.DefaultError);
            this.message = errorMessage
            this.statusCode = errorStatusCode
        }
    }
}

export default CustomFirebaseError;

/**
 * FirebaseのAPI実行&FBのエラーを独自のエラーに置き換える
*/
export const executeFirebaseApi = async<T>(executer: () => Promise<T>) => {
    try {
        return await executer();
    } catch (e) {
        if (e instanceof FirebaseError) {
            // FirebaseErrorの場合は独自のエラーに変換する
            throw new CustomFirebaseError(e)
        } else if (e instanceof AxiosError) {
            // 自前DBのエラーの場合は汎用的なエラー文言をセット
            e.message = ErrorString.DefaultError
            throw e;
        } else {
            throw e;
        }
    }
}

// FBから返却されるエラーコード
// このままだと英語なので自前のエラーに変換してからUIに表示させる
const FirebaseErrorString = {
    EmailAlreadyExists: "auth/email-already-in-use",
    InvalidEmail: "auth/invalid-email",
    InvalidUserToken: "auth/invalid-user-token",
    InvalidPassword: "auth/wrong-password",
    InvalidCredential: "auth/invalid-credential",
    UserNotFound: "auth/user-not-found",
    UserSignedOut: "auth/user-signed-out",
    UserAlreadyExist: "auth/credential-already-in-use",
    EmailAlreadyExist: "auth/email-already-in-use",
    RequiresRecentLogin: "auth/requires-recent-login",
    UserTokenExpired: "auth/user-token-expired",
    TooManyRequest: "auth/too-many-requests",
    NetworkRequestFailed: "auth/network-request-failed",
    InvalidActionCode: "auth/invalid-action-code",
    Timeout: "auth/timeout",
} as const;

// \nで改行させるために「ダブルクォーテーション」ではなく「バッククォーテーション」で囲む
export const ErrorString = {
    UnAuthorized: `ログインセッションが切れました\nお手数ですが、再度ログインしてください`,
    Timeout: `処理がタイムアウトになりました\n時間をおいてから再度お試しください`,
    NetworkNotConnected: `インターネット接続がありません`,
    InvalidEmail: `メールアドレスが違います`,
    InvalidPassword: `パスワードが違います`,
    InvalidCredential: `サーバー側でエラーが発生しました\nお手数ですがアプリ開発者に問い合わせてください`,
    EmailAlreadyExist: `このメールアドレスは既に使われています`,
    TooManyRequests: `認証の失敗が規定回数を超えたためロックされています\n時間をおいてから再度、正しい情報を入力してください`,
    DefaultError: `予期しないエラーが発生しました\nもう一度やり直してください`,
    EmailAlreadyExistMessage: `このメールアドレスは既に使われています`,
    InvalidActionCodeTitle: `リンクの有効期限が切れてる可能性があります`,
    InvalidActionCodeMessage: `もう一度、パスワード設定用のメールアドレスを送信してから、やり直してください`,
    UserNotFoundTitle: `アカウントが存在しません`,
    UserNotFoundMessage: `メールアドレスをご確認ください`
} as const;

export const ServerErrorStatusType = {
    // 最後のユーザーは削除不可
    // 403
    LastUserDeletionError: "last_user_deletion_error",
    ErrorNeedCreateJihatsukan: "error_need_create_jihatsukan",
    ErrorChangeJihatsukan: "error_need_change_jihatsukan",
    ErrorNeedChangeMonitoringOwner:"error_need_change_monitoring_owner",
    // 409
    DuplicateSupportPlanError:"duplicate_support_plan_error",
    DuplicateClassLeaderError:"duplicate_class_leader_error",
    DuplicateChildIdError:"duplicate_child_recipient_id_error",
    DuplicateClassNumberError:"duplicate_class_number_error",
    EmailAlreadyExists:"email_already_exists",
}