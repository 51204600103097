import { useMemo } from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { OtherActivities, SupportPlanDataActivity, SupportPlanDataGoal, SupportPlanDataGoalResponse, SupportPlanDataOtherActivity } from "types/support-plan";
import { deleteRequest, fetcher, putRequest } from "utils/axios";

const endpointKeyGoal = '/api/support-plan/goal'
const endpointKeyGoalGoal = '/api/support-plan/goal/goal'
const endpointKeyGoalActivity = '/api/support-plan/goal/activity'
const endpointKeyGoalOtherActivity = '/api/support-plan/goal/other-activity'
const endpointKeySubmittable = '/api/support-plan/goal/submittable'

// goalテーブル作成・更新API
export function useUpdateGoal() {
    const { trigger, isMutating } = useSWRMutation(endpointKeyGoalGoal, putRequest<SupportPlanDataGoal>);
    return { updateSupportPlanGoal: trigger, supportPlanGoalMutating: isMutating };
}

// activityテーブル作成・更新API
export function useUpdateActivity() {
    const { trigger, isMutating } = useSWRMutation(endpointKeyGoalActivity, putRequest<SupportPlanDataActivity>);
    return { updateSupportPlanActivity: trigger, supportPlanActivityMutating: isMutating };
}

// other activityテーブル作成・更新API
export function useUpdateOtherActivity() {
    const { trigger, isMutating } = useSWRMutation(endpointKeyGoalOtherActivity, putRequest<SupportPlanDataOtherActivity>);
    return { updateSupportPlanOtherActivity: trigger, supportPlanOtherActivityMutating: isMutating };
}

// goal取得API
export function useGetGoals(supportPlanId: number) {
    const { data, isLoading } = useSWR([endpointKeyGoal, { params: { supportPlanId: supportPlanId } }], fetcher<SupportPlanDataGoalResponse>);
    const memoizedValue = useMemo(
        () => ({
            supportPlanGoals: data,
            supportPlanGoalsLoading: isLoading,
        }),
        [data, isLoading]
    );
    return memoizedValue;
}

// other activities 取得API
export function useGetOtherActivities(supportPlanId: number) {
    const { data, isLoading } = useSWR([endpointKeyGoalOtherActivity, { params: { supportPlanId: supportPlanId } }], fetcher<OtherActivities>);

    const memoizedValue = useMemo(() => {
        return {
            otherActivities: data,
            otherActivitiesLoading: isLoading,
        };
    }, [data, isLoading]);

    return memoizedValue;
}

// 個別支援計画の提出が可能かどうかを判定する
export function useGetSupportPlanSubmittable(supportPlanId: number) {
    const { data, isLoading, mutate } = useSWR([endpointKeySubmittable, { params: { supportPlanId: supportPlanId } }], fetcher<boolean>);
    const memoizedValue = useMemo(
        () => ({
            isSubmittable: data,
            isLoading: isLoading,
            mutate: mutate
        }),
        [data, isLoading]
    );
    return memoizedValue;
}

export function useDeleteGoal() {
    const { trigger, isMutating } = useSWRMutation(endpointKeyGoal, deleteRequest<void>);
    return { deleteGoals: trigger, isDeletingGoals: isMutating };
}