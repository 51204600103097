interface Query {
    key: string
    value: any
}

// ページング可能なAPIエンドポイントを生成する
// エンドポイントを(取得、更新、作成、削除)で共有することでキャッシュが共有される
export function generatePageableEndPoint(
    pageIndex: number,
    pageSize: number,
    endpointKey: string,
    queryList: Query[] | undefined = undefined
) {
    // 基本のクエリパラメータ（ページング）
    const baseParams = `page=${pageIndex}&size=${pageSize}`;

    // 追加のクエリパラメータが存在する場合の処理
    const additionalParams = queryList
        ?.filter(item => item.value !== undefined && item.value !== null)
        .map(item => {
            // 配列のクエリパラメータをカンマ区切りの文字列に変換
            if (Array.isArray(item.value)) {
                return `${encodeURIComponent(item.key)}=${encodeURIComponent((item.value as any[]).join(','))}`;
            }
            return `${encodeURIComponent(item.key)}=${encodeURIComponent(item.value)}`;
        })
        .join('&');

    return `${endpointKey}?${baseParams}${additionalParams ? '&' + additionalParams : ''}`;
}
