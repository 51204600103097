import { Outlet } from 'react-router-dom';
import ApiStatusGuard from 'utils/route-guard/ApiStatusGuard';

// ==============================|| LAYOUT - AUTH ||============================== //
// 全てのページ(ログイン・非ログイン関係なく)に適用させる処理を記述する
const ErrorHandlerLayout = () => (
  <ApiStatusGuard>
    <Outlet />
  </ApiStatusGuard>
);

export default ErrorHandlerLayout;