import { PageUrl } from 'const/page-url';
import { SUPPORT_PLAN_HUB_SESSION_STRING } from 'pages/support-plan/base';
import { Navigate, Outlet } from 'react-router-dom';

const PdfGuardLayout = () => {

    // URL直叩き防止処理
    // PDF出力時はSupportPlanDataをローカルストレージ経由で取得するため、保存されてなければ遷移させない
    if (localStorage.getItem(SUPPORT_PLAN_HUB_SESSION_STRING) == null) {
        return <Navigate
            to={{
                pathname: PageUrl.SupportPlanList.urlWithSlash,
            }}
        />
    }
    return <Outlet />
};

export default PdfGuardLayout;