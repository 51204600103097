// material-ui
import { Button, Divider, CardContent, Modal, Stack, Typography } from '@mui/material';

// project import
import MainCard from 'components/MainCard';

interface BasicModalType {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  title: string,
  description: string,
  submitButtonString: string,
  onCLickPositiveButton: () => void;
}

// ==============================|| MODAL - BASIC ||============================== //

export default function BasicModal({ open, setOpen, title, description, submitButtonString, onCLickPositiveButton }: BasicModalType) {

  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <MainCard title={title} modal darkTitle content={false}>
        <CardContent>
          <Typography id="modal-modal-description">{description}</Typography>
        </CardContent>
        <Divider />
        <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ px: 2.5, py: 2 }}>
          <Button color="error" size="small" onClick={handleClose}>
            キャンセル
          </Button>
          <Button variant="contained" size="small" onClick={onCLickPositiveButton}>
            {submitButtonString}
          </Button>
        </Stack>
      </MainCard>
    </Modal>
  );
}
