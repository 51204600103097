// assets
import {
    AppstoreAddOutlined,
    UploadOutlined,
    CheckSquareOutlined,
    BarChartOutlined
} from '@ant-design/icons';
import { PageUrl } from 'const/page-url';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
    AppstoreAddOutlined,
    UploadOutlined,
    CheckSquareOutlined,
    BarChartOutlined,
};

// ==============================|| MENU ITEMS - 開発者専用メニュー ||============================== //

const menuDeveloper: NavItemType = {
    id: 'group-developer',
    title: <div>開発者専用メニュー</div>,
    icon: icons.AppstoreAddOutlined,
    needDeveloperAuthority: true,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <div>ダッシュボード</div>,
            type: 'item',
            url: PageUrl.DashBoard.urlWithSlash,
            icon: icons.BarChartOutlined,
            breadcrumbs: false
        },
        {
            id: 'import-csv',
            title: <div>CSVアップロード</div>,
            type: 'item',
            url: PageUrl.ImportCSV.urlWithSlash,
            icon: icons.UploadOutlined,
            breadcrumbs: false
        },
        {
            id: 'migration-test',
            title: <div>マイグレーションテスト</div>,
            type: 'item',
            url: PageUrl.MigrationTest.urlWithSlash,
            icon: icons.CheckSquareOutlined,
            breadcrumbs: false
        },
    ]
};

export default menuDeveloper;