import { AxiosRequestConfig } from 'axios';
import { axiosClient } from 'components/AxiosConfig';

// GETリクエスト
export const fetcher = async<T>(args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosClient.get<T>(url, { ...config });

  return res.data;
};

// POSTリクエスト
export async function postRequest<T>(url: string, { arg }: { arg: any }) {

  const res = await axiosClient.post<T>(url, { ...arg });

  return res.data;
}

// PUTリクエスト
export async function putRequest<T>(url: string, { arg }: { arg: any }) {

  const res = await axiosClient.put<T>(url, { ...arg });

  return res.data;
}

// DELETEリクエスト
export async function deleteRequest<T>(url: string, { arg }: { arg: any }) {
  // Deleteメソッドはbodyは必要なく、削除するIDをクエリーとしてURLに載せれば良い
  const res = await axiosClient.delete<T>(url, {
    params: { id: arg }
  });
  return res.data;
}