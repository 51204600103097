import { RouterProvider } from 'react-router-dom';
import router from 'routes';
import ScrollTop from 'components/ScrollTop';
import ThemeCustomization from 'themes';
import { AuthProvider } from 'contexts/AuthContext';
import SnackBarProvider from 'components/third-party/Notistack';
import Snackbar from 'components/@extended/Snackbar';
import { SWRSetting } from 'components/SWRSetting';
import { ApiStatusTransitionProvider } from 'contexts/ApiStatusTransitionContext';
import FirebaseReAuthModalProvider from 'components/FirebaseReAuthModalProvider';
import AxiosConfig from 'components/AxiosConfig';

function App() {
  return (
    <ThemeCustomization>
      <ScrollTop>
          <ApiStatusTransitionProvider>
            <AuthProvider>
              <FirebaseReAuthModalProvider>
                <SnackBarProvider>
                  <AxiosConfig>
                    <SWRSetting>
                      <RouterProvider router={router} />
                      <Snackbar />
                    </SWRSetting>
                  </AxiosConfig>
                </SnackBarProvider>
              </FirebaseReAuthModalProvider>
            </AuthProvider>
          </ApiStatusTransitionProvider>
      </ScrollTop>
    </ThemeCustomization >
  );
}

export default App;
