import Loadable from "components/Loadable";
import { PageUrl } from "const/page-url";
import { lazy } from "react";
const AuthSetting = Loadable(lazy(() => import('pages/auth/auth-setting')));

// ==============================|| AUTH ROUTING ||============================== //
const NoRuleRoutes = {
    path: '/',
    children: [
        {
            path: PageUrl.AuthSetting.url,
            element: <AuthSetting />
        },
    ]
};

export default NoRuleRoutes;