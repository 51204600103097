import { Box, Grid, Typography } from "@mui/material"
import MainCard from "components/MainCard"
import React from "react"

export interface KeyAndValue { key: string, value: any }
interface Info { title?: string, info: KeyAndValue[], keyWidth?: number, valueWidth?: number }

function InfoCard({ title, info, keyWidth = 3 }: Info) {

    const Row = ({ key, value }: KeyAndValue) => {
        return <React.Fragment>
            <Grid item xs={keyWidth}>
                <Typography color="textSecondary" noWrap>{key} :</Typography>
            </Grid>
            <Grid item xs={12 - keyWidth}>
                <Typography>{value}</Typography>
            </Grid>
        </React.Fragment>
    }

    return <MainCard content={false} sx={{ mt: 1.5 }}>
        <Box sx={{ p: 4 }}>
            {title && (
                <Typography variant="h5" sx={{ mb: 4 }}>{title}</Typography>
            )}
            <Grid container spacing={2}>
                {info.map((item) => { return Row(item) })}
            </Grid>
        </Box>
    </MainCard>
}

export default InfoCard;