import UpdateIcon from '@mui/icons-material/Update';
import { Button, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { PageUrl } from 'const/page-url';
import { SupportPlanStatus } from 'const/support-plan';
import { SupportPlanHubProps } from "pages/support-plan/base";
import React from 'react';
import { useNavigate } from 'react-router';

function UpdateSupportPlan({ data }: SupportPlanHubProps) {
    const navigate = useNavigate();
    return (data.isCurrentSupportPlan ? (<React.Fragment>
        <Typography variant="h2">個別支援計画書更新</Typography>
        <MainCard content={false} sx={{ mt: 1.5, p: 3 }}>
            <Button
                variant="contained"
                disabled={data.supportPlanStatus != SupportPlanStatus.FINISHED_MONITORING}
                size="medium"
                sx={{ m: 1.5 }}
                startIcon={<UpdateIcon />}
                color='error'
                onClick={() => {
                    navigate(PageUrl.UpdateSupportPlanInfo.urlWithSlash, { state: data });
                }}>
                個別支援計画書を更新する
            </Button>
        </MainCard>
    </React.Fragment>) : (<React.Fragment />))
}

export default UpdateSupportPlan;