import { Outlet } from 'react-router-dom';
import AuthGuard from 'utils/route-guard/AuthGuard';

// ==============================|| LAYOUT - AUTH ||============================== //

const AuthorizedLayout = () => (
  <AuthGuard>
    <Outlet />
  </AuthGuard>
);

export default AuthorizedLayout;
