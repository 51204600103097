import { useMemo } from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { Monitoring } from "types/support-plan";
import { deleteRequest, fetcher, postRequest, putRequest } from "utils/axios";

const endpoint = 'api/monitoring'
const endpointChangeOwner = 'api/monitoring/change-owner'

export function useUpdateMonitoring() {
    const { trigger, isMutating } = useSWRMutation(endpoint, postRequest<void>);
    return { trigger: trigger, isMutating: isMutating };
}

// モニタリングのownerを変更するAPI
export function useChangeMonitoringOwner() {
    const { trigger, isMutating } = useSWRMutation(endpointChangeOwner, putRequest<void>);
    return { changeOwner: trigger, isChanging: isMutating };
}

export function useGetMonitoring(supportPlanId: number) {
    const { data, isLoading } = useSWR([endpoint, { params: { supportPlanId: supportPlanId } }], fetcher<Monitoring[]>);
    const memoizedValue = useMemo(
        () => ({
            data: data,
            isLoading: isLoading
        }),
        [data, isLoading]
    );

    return memoizedValue;
}

export function useDeleteMonitoring() {
    const { trigger, isMutating } = useSWRMutation(endpoint, deleteRequest<void>);
    return { deleteMonitoring: trigger, isDeleting: isMutating };
}
