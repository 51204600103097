import { useContext } from 'react';

// ApiStatusTransition provider
import { ApiStatusTransitionContext } from 'contexts/ApiStatusTransitionContext';

// ==============================|| ApiStatusTransition HOOKS ||============================== //

const useApiStatusTransition = () => {
  const context = useContext(ApiStatusTransitionContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useApiStatusTransition;
