import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import { PageUrl } from 'const/page-url';
import DeveloperAuthorizedLayout from 'layout/DeveloperAuthorizedRoutes';
import DashboardLayout from 'layout/Dashboard';
const ImportCSV = Loadable(lazy(() => import('pages/admin/import-csv')));
const MigrationTest = Loadable(lazy(() => import('pages/admin/migration-test')));

// ==============================|| AUTH ROUTING ||============================== //
const DeveloperAuthorizedRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: <DeveloperAuthorizedLayout />,
            children: [
                {
                    path: '/',
                    element: <DashboardLayout />,
                    children: [
                        {
                            path: PageUrl.ImportCSV.url,
                            element: <ImportCSV />
                        },
                        {
                            path: PageUrl.MigrationTest.url,
                            element: <MigrationTest />
                        },
                    ]
                }
            ]
        }
    ]
};

export default DeveloperAuthorizedRoutes;