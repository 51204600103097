import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import {  LogoutOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { when } from 'utils/when';
import { PageUrl } from 'const/page-url';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
}

const ProfileTab = ({ handleLogout }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    setSelectedIndex(index);
    when(index)
      .on(
        (value) => value == 0,
        () => {
          navigate(PageUrl.Profile.urlWithSlash, { replace: false });
        }
      )
      .on(
        (value) => value == 1,
        () => {
          handleLogout()
        }
      )
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton selected={selectedIndex === 0} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 0)}>
        <ListItemIcon>
          <InfoCircleOutlined />
        </ListItemIcon>
        <ListItemText primary="プロフィール編集" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 1} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 1)}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="ログアウト" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
