import { Button, Card, CardContent, Grid, Typography } from "@mui/material"
import { Variant } from "@mui/material/styles/createTypography";
import { GenericCardProps } from "types/root"


interface TitleButtonCardProps extends GenericCardProps {
    buttonLabel: string;
    primaryLabelVariant?: Variant
    secondaryLabelVariant?: Variant
    onClickButton?:  React.MouseEventHandler<HTMLButtonElement> | undefined
}

const TitleButtonCard = ({ primary, secondary, color, buttonLabel, primaryLabelVariant, secondaryLabelVariant, onClickButton }: TitleButtonCardProps) => {

    return <Card
        elevation={0}
        sx={{
            background: color,
            position: 'relative',
            color: '#fff',
            '&:hover svg': {
                opacity: 1,
                transform: 'scale(1.1)'
            }
        }}
    >
        <CardContent>
            <Button
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 25,
                    color: '#fff',
                    '& svg': {
                        width: 36,
                        height: 36,
                        opacity: 0.5,
                        transition: 'all .3s ease-in-out'
                    }
                }}
                variant="contained" size="medium" onClick={onClickButton}>
                {buttonLabel}
            </Button>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Typography variant={primaryLabelVariant ? primaryLabelVariant : "h5"} color="inherit" sx={{ fontWeight: "bold" }}>
                        {primary}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={secondaryLabelVariant ? secondaryLabelVariant : "body2"} color="inherit" sx={{ fontWeight: "bold" }}>
                        {secondary}
                    </Typography>
                </Grid>
            </Grid>
        </CardContent>
    </Card >
}

export default TitleButtonCard;