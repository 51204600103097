import { when } from "utils/when"

export class Authority {
    private static _values = new Array<Authority>()
    public static readonly CEO = new Authority(1, '管理者')
    public static readonly LEADER = new Authority(2, '教室長')
    public static readonly STAFF = new Authority(3, 'スタッフ')
    public static readonly ADMIN = new Authority(99, '開発者')

    private constructor(
        public readonly code: number,
        public readonly label: string
    ) {
        Authority._values.push(this)
    }
    static get values() {
        return this._values
    }
    static findFromCode(code?: number) {
        return this.values.find(authority => authority.code === code) ?? Authority.STAFF
    }
    static getAuthorityLabel(code?: number): string {
        return when(code)
            .on(
                (value) => value === 1,
                () => "管理者"
            )
            .on(
                (value) => value === 2,
                () => "教室長"
            )
            .on(
                (value) => value === 3,
                () => "スタッフ"
            )
            .on(
                (value) => value === 99,
                () => "開発者"
            )
            .otherwise(() => "スタッフ");
    }

    equals(other: Authority) {
        return this.code === other.code
    }
    toString() {
        return `${this.code}`
    }
}