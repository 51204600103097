// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 * import { ThemeMode } from 'types/config';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon} alt="Mantis" width="100" />
     *
     */
<svg width="129" height="129" viewBox="0 0 129 129" xmlns="http://www.w3.org/2000/svg">
  <path d="M35,80 L49,50 L63,80 L77,50 L91,80 L77,110 L63,80 L49,110 Z" fill={theme.palette.primary.main}/>
</svg>
  );
};

export default LogoIcon;
