import { createBrowserRouter } from 'react-router-dom';

// project import
import ErrorHandlerLayout from 'layout/Errorhandler';
import ErrorBoundary from './ErrorBoundary';
import UnAuthorizedRoutes from './UnAuthorizedRoutes';
import AuthorizedRoutes from './AuthorizedRoutes';
import NoRuleRoutes from './NoruleRoutes';
import DeveloperAuthorizedRoutes from './DeveloperAuthorizedRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <ErrorHandlerLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        // ログイン前のページ群
        UnAuthorizedRoutes,
        // ログイン後のページ群
        AuthorizedRoutes,
        // 開発者のみアクセスできるページ群
        DeveloperAuthorizedRoutes,
        // ログインを問わず表示可能なページ群
        NoRuleRoutes
      ]
    }
  ],
  { basename: process.env.REACT_APP_BASE_NAME }
);

export default router;
