// project import
import menuMain from './main';
// types
import { NavItemType } from 'types/menu';
import menuSetting from './setting';
import menuDeveloper from './developer';

// ==============================|| MENU ITEMS ||============================== //
const menuItems: { items: NavItemType[] } = {
  items: [menuMain, menuSetting, menuDeveloper]
};

export default menuItems;
