import { createContext, useEffect, useReducer } from "react";
import { ApiStatusTransitionContextType, ApiStatusTransitionProps } from "types/api-status-transition";
import apiStatusTransitionReducer from "./api-status-transition-reducer/api-status-transition";
import { INITIALIZE, INTERNAL_SEVER_ERROR, MAINTENANCE, UNAUTHORIZED } from "./api-status-transition-reducer/actions";
import Loader from "components/Loader";

export const initialState: ApiStatusTransitionProps = {
    isInitialized: false,
    is403: false,
    is500: false,
    is503: false,
}

export const ApiStatusTransitionContext = createContext<ApiStatusTransitionContextType | null>(null);

export const ApiStatusTransitionProvider = ({ children }: { children: React.ReactElement }) => {

    // Redux
    const [stateFromStore, dispatch] = useReducer(apiStatusTransitionReducer, initialState);

    useEffect(() => {
        if (stateFromStore.isInitialized !== undefined && !stateFromStore.isInitialized) {
            dispatch({ type: INITIALIZE });
        }
    }, [dispatch])

    const executeUnAuthorizeTransition = () => {
        dispatch({ type: UNAUTHORIZED });
    }

    const executeInternalServerErrorTransition = () => {
        dispatch({ type: INTERNAL_SEVER_ERROR });
    }

    const executeMaintenanceTransition = () => {
        dispatch({ type: MAINTENANCE });
    }

    const initialize = () => {
        dispatch({ type: INITIALIZE });
    }

    if (stateFromStore.isInitialized !== undefined && !stateFromStore.isInitialized) {
        return <Loader />;
    }

    return (
        <ApiStatusTransitionContext.Provider
            value={{
                // storeに登録したユーザーのデータもアクセスできるようにする (403,500,503など)
                ...stateFromStore,
                executeUnAuthorizeTransition: executeUnAuthorizeTransition,
                executeInternalServerErrorTransition: executeInternalServerErrorTransition,
                executeMaintenanceTransition: executeMaintenanceTransition,
                initialize: initialize
            }}
        >
            {children}
        </ApiStatusTransitionContext.Provider>
    );
}