import { EditOutlined } from "@ant-design/icons";
import { Button, Grid, List, Typography } from "@mui/material";
import InfoCard, { KeyAndValue } from "sections/card/InfoCard";
import PageTopIconCard from "sections/page-top-card/PageTopIconCard";
import { getSchoolCategory, getServiceCategory, getServiceCategoryNumber } from "utils/data-converter";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { SupportPlanStatus, SupportPlanStatusType, getSupportPlanStatusLabel } from "const/support-plan";
import { SupportPlanHubProps } from "pages/support-plan/base";
import { PageUrl } from "const/page-url";
import { useNavigate } from "react-router";
import { formatToKanjiDateString } from "const/date-formatter";
import { useGetSupportPlanListByChildId } from "api/support-plan";
import Loader from "components/Loader";
import { SxProps, Theme } from "@mui/system";
import { useEffect, useState } from "react";
import { ServiceCategory } from "types/child";
import { getSelectedDaysStrByBitmask } from "const/day-of-week";

function BasicInfo({ data, setData }: SupportPlanHubProps) {

    const navigate = useNavigate();
    const { supportPlanList, isLoading } = useGetSupportPlanListByChildId(data.childId)
    const createTimeDivisionLabel = (divisionNumber: number | undefined) => {
        if (divisionNumber == undefined) return ""
        return "区分" + divisionNumber.toString()
    }

    const flexContainer: SxProps<Theme> = {
        overflowX: "auto",
        scrollbarWidth: "none",
        whiteSpace: "nowrap",
        webkitOverflowScrolling: "touch"
    };
    const [selectedPlanNumber, setSelectedPlanNumber] = useState<number>(data.supportPlanNumberPerChild)
    const [list, setList] = useState<KeyAndValue[]>([])
    useEffect(() => {
        if (data.childServiceCategory == getServiceCategoryNumber(ServiceCategory.CHILD_DEVELOPMENT_SUPPORT)) {
            setList([
                { key: "利用者氏名", value: data.childFullName },
                { key: "受給者者番号", value: data.recipientId },
                { key: "入力状況", value: getSupportPlanStatusLabel(data.supportPlanStatus as SupportPlanStatusType) },
                { key: "事業所名", value: data.className },
                { key: "計画書NO", value: data.supportPlanNumberPerChild },
                { key: "支援期間", value: formatToKanjiDateString(data?.supportStartDate!) + " ~ " + formatToKanjiDateString(data?.supportUpdateDate!) },
                { key: "利用サービス", value: getServiceCategory(data.childServiceCategory) },
                { key: "通学区分", value: getSchoolCategory(data.childSchoolCategory) },
                { key: "個別支援計画（案）作成者", value: data.preSupportPlanOwnerName },
                { key: "個別支援計画（本案）作成者", value: data.supportPlanOwnerName },
                { key: "生活に対する困り事・ニーズ (保護者)", value: data.guardianNeeds },
                { key: "生活に対する困り事・ニーズ (児童)", value: data.childNeeds },
                { key: "総合的な支援の方針", value: data.generalSupportDirection },
                { key: "個別支援会議開催日 ", value: data.supportPlanInternalMeetingDate },
                { key: "保護者・児童に対する説明日 ", value: data.supportPlanExternalMeetingDate },
                { key: "会議出席者 ", value: data.meetingAttendee },
                { key: "特記事項 ", value: data.supportPlanNote },
                { key: "相談支援事業所名 ", value: data.consultationSupportFacility },
                { key: "相談支援専門員 ", value: data.consultationSupporter },
                { key: "時間区分 (児童発達支援)", value: createTimeDivisionLabel(data.timeDivisionDevelopmentSupport) },
                { key: "サービス利用予定曜日", value: getSelectedDaysStrByBitmask(data.supportWeekDay) },
            ])
        } else {
            setList(
                [
                    { key: "利用者氏名", value: data.childFullName },
                    { key: "受給者者番号", value: data.recipientId },
                    { key: "入力状況", value: getSupportPlanStatusLabel(data.supportPlanStatus as SupportPlanStatusType) },
                    { key: "事業所名", value: data.className },
                    { key: "計画書NO", value: data.supportPlanNumberPerChild },
                    { key: "支援期間", value: formatToKanjiDateString(data?.supportStartDate!) + " ~ " + formatToKanjiDateString(data?.supportUpdateDate!) },
                    { key: "利用サービス", value: getServiceCategory(data.childServiceCategory) },
                    { key: "通学区分", value: getSchoolCategory(data.childSchoolCategory) },
                    { key: "個別支援計画（案）作成者", value: data.preSupportPlanOwnerName },
                    { key: "個別支援計画（本案）作成者", value: data.supportPlanOwnerName },
                    { key: "生活に対する困り事・ニーズ (保護者)", value: data.guardianNeeds },
                    { key: "生活に対する困り事・ニーズ (児童)", value: data.childNeeds },
                    { key: "総合的な支援の方針", value: data.generalSupportDirection },
                    { key: "個別支援会議開催日 ", value: data.supportPlanInternalMeetingDate },
                    { key: "保護者・児童に対する説明日 ", value: data.supportPlanExternalMeetingDate },
                    { key: "会議出席者 ", value: data.meetingAttendee },
                    { key: "特記事項 ", value: data.supportPlanNote },
                    { key: "相談支援事業所名 ", value: data.consultationSupportFacility },
                    { key: "相談支援専門員 ", value: data.consultationSupporter },
                    { key: "時間区分 (放課後デイサービス・授業終了後)", value: createTimeDivisionLabel(data.timeDivisionAfterSchool) },
                    { key: "時間区分 (放課後デイサービス・休業日)", value: createTimeDivisionLabel(data.timeDivisionHoliday) },
                    { key: "サービス利用予定曜日", value: getSelectedDaysStrByBitmask(data.supportWeekDay) },
                ])
        }
    }, [data])

    return <Grid item xs={12} >
        {(isLoading || supportPlanList == undefined) && <Loader />}
        <PageTopIconCard title='個別支援計画ハブ' description='個別支援計画に関する各画面に遷移できます' icon={<AutoStoriesIcon fontSize="large" color='primary' />} />
        {supportPlanList != undefined && supportPlanList!.length > 0 && <Grid item xs={12} sx={{ mt: 1.5 }}>
            <List sx={flexContainer}>
                {supportPlanList.map((item) => {
                    return <Button
                        variant="dashed"
                        color={selectedPlanNumber == item.supportPlanNumberPerChild ? "info" : "secondary"}
                        sx={{ mr: 2 }}
                        onClick={() => {
                            setSelectedPlanNumber(item.supportPlanNumberPerChild)
                            setData(item)
                        }}>
                        計画書NO : {item.supportPlanNumberPerChild}
                    </Button>
                })}
            </List>
        </Grid>
        }

        <Grid item xs={12} sx={{ mt: 1.5 }}>
            <Typography variant="h2">基本情報</Typography>
        </Grid>
        <Grid item xs={12}>
            <InfoCard info={list} keyWidth={4} />
            <Button
                variant="contained"
                size="large"
                sx={{ mt: 3.5 }}
                startIcon={<EditOutlined />}
                disabled={!data.isCurrentSupportPlan || data.supportPlanStatus >= SupportPlanStatus.FINISHED_CREATING_PLAN}
                onClick={() => {
                    navigate(PageUrl.EditBasicInfo.urlWithSlash, { state: data })
                }}>
                基本情報編集
            </Button>
        </Grid>
    </Grid>

}

export default BasicInfo;