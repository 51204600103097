// assets
import {
  AppstoreAddOutlined,
  LinkOutlined,
  LockOutlined,
  HomeFilled,
  BookOutlined,
  UserOutlined,
  BankOutlined,
  InfoCircleOutlined,
  UserSwitchOutlined,
  QuestionOutlined
} from '@ant-design/icons';
import { PageUrl } from 'const/page-url';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  AppstoreAddOutlined,
  LinkOutlined,
  LockOutlined,
  HomeFilled,
  BookOutlined,
  UserOutlined,
  BankOutlined,
  InfoCircleOutlined,
  UserSwitchOutlined,
  QuestionOutlined,
};

// ==============================|| MENU ITEMS - その他 ||============================== //

const menuSetting: NavItemType = {
  id: 'group-setting',
  title: <div>設定</div>,
  icon: icons.AppstoreAddOutlined,
  needDeveloperAuthority: false,
  type: 'group',
  children: [
    {
      id: 'staff',
      title: <div>スタッフ</div>,
      type: 'item',
      url: PageUrl.Staff.urlWithSlash,
      icon: icons.UserOutlined,
      breadcrumbs: false
    },
    {
      id: 'class',
      title: <div>事業所</div>,
      type: 'item',
      url: PageUrl.Class.urlWithSlash,
      icon: icons.BankOutlined,
      breadcrumbs: false
    },
    {
      id: 'profile',
      title: <div>プロフィール編集</div>,
      type: 'item',
      url: PageUrl.Profile.urlWithSlash,
      icon: icons.InfoCircleOutlined,
      breadcrumbs: false
    },
    {
      id: 'operation-manual',
      title: <div>操作マニュアル</div>,
      type: 'item',
      url: 'https://docs.google.com/document/d/1nMx3MHpF_VaPANvymGHZMdzd4-xT4QRO_Q5y3N3b46A/edit',
      icon: icons.QuestionOutlined,
      external: true,
      target: true,
      chip: {
        label: 'drive',
        color: 'secondary',
        size: 'small'
      }
    },
  ]
};

export default menuSetting;