// action - state management
import { INITIALIZE, INTERNAL_SEVER_ERROR, MAINTENANCE, UNAUTHORIZED } from './actions';

// types
import { ApiStatusTransitionProps, ApiStatusTransitionActionProps } from 'types/api-status-transition';

// initial state
export const initialState: ApiStatusTransitionProps = {
    isInitialized: false,
    is403: false,
    is500: false,
    is503: false,
}

// ==============================|| REDUCER ||============================== //

const apiStatusTransitionReducer = (state = initialState, action: ApiStatusTransitionActionProps) => {
    switch (action.type) {
        case INITIALIZE: {
            return {
                ...state,
                is403: false,
                is500: false,
                is503: false,
                isInitialized: true
            };
        }
        case UNAUTHORIZED: {
            return {
                ...state,
                is403: true,
                is500: false,
                is503: false,
                isInitialized: true
            };
        }
        case INTERNAL_SEVER_ERROR: {
            return {
                ...state,
                is403: false,
                is500: true,
                is503: false,
                isInitialized: true
            };
        }
        case MAINTENANCE: {
            return {
                ...state,
                is403: false,
                is500: false,
                is503: true,
                isInitialized: true
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default apiStatusTransitionReducer;
