import { lazy } from 'react';

// project import
import UnAuthorizedLayout from 'layout/UnAuthorized';
import Loadable from 'components/Loadable';
import { PageUrl } from 'const/page-url';
import MaintenanceGuard from 'layout/MaintenanceGuard';

// render - login
// 遅延読み込みで初期読み込み時間やデータ転送量を減らす
// かつ読み込み時はローディングを表示させる
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const ForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const ResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));

// ==============================|| AUTH ROUTING ||============================== //
const UnAuthorizedRoutes = {
  path: '/',
  element: <UnAuthorizedLayout />,
  children: [
    {
      path: PageUrl.Login.url,
      element: <AuthLogin />
    },
    {
      path: '/',
      element: <MaintenanceGuard />,
      children: [
        {
          path: PageUrl.ResetPassword.url,
          element: <ResetPassword />
        },
        {
          path: PageUrl.ForgotPassword.url,
          element: <ForgotPassword />
        },
        {
          path: PageUrl.CheckEmail.url,
          element: <AuthCheckMail />
        },
      ]
    }
  ]
};

export default UnAuthorizedRoutes;
