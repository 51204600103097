import { useMemo } from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { Answers, AssessmentCategoryStatus, AssessmentScoresResponse } from "types/support-plan";
import { fetcher, postRequest, putRequest } from "utils/axios";

const endpointKeys = {
    list: 'api/support-plan/assessment',
    status: 'api/support-plan/assessment-status',
    score: 'api/support-plan/assessment/score',
}

// assessment入力状況一覧取得API
export function useGetAssessmentStatus(systemClassificationId: number, supportPlanId: number) {
    const { data, isLoading } = useSWR([endpointKeys.status, {
        params: {
            systemClassificationId: systemClassificationId,
            supportPlanId: supportPlanId
        }
    }], fetcher<AssessmentCategoryStatus[]>);
    const memoizedValue = useMemo(
        () => ({
            assessmentStatus: data as AssessmentCategoryStatus[],
            assessmentStatusLoading: isLoading
        }),
        [data, isLoading]
    );
    return memoizedValue;
}

// assessment結果一覧取得API
export function useGetAssessmentAnswers(supportPlanId: number) {
    const { data, isLoading } = useSWR([endpointKeys.list, {
        params: { supportPlanId: supportPlanId }
    }], fetcher<Answers>);
    const memoizedValue = useMemo(
        () => ({
            answers: data as Answers,
            answersLoading: isLoading
        }),
        [data, isLoading]
    );
    return memoizedValue;
}

// assessment のスコア取得API
export function useGetAssessmentScore(supportPlanId: number) {
    const { data, isLoading } = useSWR([endpointKeys.score, {
        params: { supportPlanId: supportPlanId }
    }], fetcher<AssessmentScoresResponse>);
    const memoizedValue = useMemo(
        () => ({
            scores: data as AssessmentScoresResponse,
            scoresLoading: isLoading
        }),
        [data, isLoading]
    );
    return memoizedValue;
}

// assessment作成API
export function useCreateAssessment() {
    const { trigger, isMutating } = useSWRMutation(endpointKeys.list, postRequest<void>);
    return { createAssessment: trigger, isCreating: isMutating };
}

// assessment更新API
export function useUpdateAssessment() {
    const { trigger, isMutating } = useSWRMutation(endpointKeys.list, putRequest<void>);
    return { updateAssessment: trigger, isMutating };
}